export function fromDateFilterFn(rows, id, filterValue) {
  let filteredDate = new Date(filterValue);
  return rows.filter((r) => {
    var time = new Date(r.values[id]);
    if (filterValue.length === 0) return rows;
    if (filterValue[0]) return time >= filteredDate;
  });
}
fromDateFilterFn.autoRemove = (val) => !val;

export function toDateFilterFn(rows, id, filterValue) {
  let filteredDate = new Date(filterValue);
  return rows.filter((r) => {
    var time = new Date(r.values[id] && r.values[id].slice(0, 10));
    if (filterValue.length === 0) return rows;
    if (filterValue[0]) return time <= filteredDate;
  });
}

toDateFilterFn.autoRemove = (val) => !val;
