import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import { AppUtils } from "utils/utils";

export default function HomeEvents() { 

  const {
    data: events,
    isLoading,
    error,
  } = useFetchData(ApiConstants.getEvents);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  return (
    <div className="   bg-white  rounded-md p-5 h-[90%]   overflow-auto ">
      <div className="text-sm font-semibold pb-3 text-red-500">
        Son etkinlikler
      </div>
      <div className="text-slate-700 text-xs  overflow-auto ">
        {events &&
          events.map((v) => (
            <div key={v._id}>
              <div className="py-2">
                <span className="text-red-400 font-semibold">
                  {" "}
                  {AppUtils.formatClock(v.createdAt) + " • "}
                </span>
                <span className="text-gray-500 "> {v.value}</span>
              </div>
              <div className="h-[1px] bg-slate-100 w-full my-1"></div>
            </div>
          ))}
      </div>
    </div>
  );
}
