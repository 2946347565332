import classNames from "classnames";
import { useField } from "formik";
import { InputText } from 'primereact/inputtext';
 

export default function CustomInput({ label, type, mask, ...props }) {
  const [field, meta, helpers] = useField(props); 
  return (
    <>
      <div
        className={classNames({
          "hidden ": props.hidden,
        })}
      >
        <label className="  relative flex shadow-red-500 rounded-sm border-gray-400 text-base">
          <InputText 
    
            type={type}
            className={classNames(buildStyle(), {
              "pt-[1.32rem] ":  field.value != null, 
              "pt-[1.32rem] ":  field.value != '', 
              "bg-red-100 select-none pointer-events-none ": props.disabled,
            })}    
            onChange={(e) => { 
              helpers.setValue(e.target.value);
            }}  
            value={field.value } 
            {...props}
          />
          <small
            className={classNames({
              "absolute left-[13px] cursor-text pointer-events-none text-gray-400 -translate-y-1/2 transition-all ": true,
              "text-sm top-1/2":  field.value == null ,
              "text-sm top-1/2":  field.value == '' ,
              "text-[13px]  top-3": field.value != null,
              "text-[13px]  top-3": field.value != '',
            })}
          >
            {label}
          </small>
        </label>
      </div>
    </>
  );

  function buildStyle() {
    return "border-none flex w-full text-inputText  shrink-0  bg-inputBackground rounded-lg  ";
  }
}
