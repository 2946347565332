import {
  MdFirstPage,
  MdLastPage,
  MdNavigateNext,
  MdNavigateBefore,
} from "react-icons/md";

export default function TablePagination(
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageCount,
  state,
  pageOptions,
  setPageSize,
  enablePageNumbers
) {
  const buildBtnClass =
    "flex items-center justify-center w-10 h-10 text-gray-500 transition-colors duration-150 rounded-full focus:shadow-outline hover:bg-gray-100";

  return (
    <div className="pagination flex justify-end items-center pt-4">
    {enablePageNumbers && (
      <nav aria-label="Page navigation items-center">
        <ul className="inline-flex space-x-2  items-center">
          <li>
            <button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              className={buildBtnClass}
            >
              <MdFirstPage />
            </button>
          </li>
          <li>
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className={buildBtnClass}
            >
              <MdNavigateBefore />
            </button>
          </li>

          {enablePageNumbers && (
            <span className="text-sm">
              Page{" "}
              <strong className="text-red-500">
                {state.pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
          )}
          <li>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className={buildBtnClass}
            >
              <MdNavigateNext />
            </button>
          </li>
          <li>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              className={buildBtnClass}
            >
              <MdLastPage />
            </button>
          </li>
        </ul>
      </nav>
    )}
  </div>
  );
}
