import classNames from "classnames";
import { useField } from "formik";
import { InputNumber } from "primereact/inputnumber"; 


export default function CustomInputNumber({ label, mask, ...props }) {
  const [field, meta, helpers] = useField(props);

  return (
    <>
      <div
        className={classNames({
          "hidden ": props.hidden,
        })}
      >
        <label className="  relative flex shadow-red-500  rounded-sm border-gray-400 text-base">
          <InputNumber
            type="text"
            className="w-full "
            inputClassName={classNames(buildStyle(), {
              "pt-[1.32rem] ": field.value != null,
              "bg-red-100 select-none pointer-events-none ": props.disabled,
            })}
            onChange={(e) => {
              helpers.setValue(e.value);
            }}
            value={field.value}
            {...props}
          />
          <div
            className={classNames({
              "absolute left-[13px] cursor-text pointer-events-none text-gray-400 -translate-y-1/2 transition-all ": true,
              "text-sm top-1/2": field.value == null,
              "text-[13px] top-3": field.value != null,
            })}
          >
            {label}
          </div>

          {props.suffix_icon && (
            <div
              className={classNames({
                "absolute right-0     pr-3 text-gray-400 translate-y-2   transition-all ": true,
                "text-sm top-1/2": field.value == null,
                "text-[13px] top-3": field.value != null,
              })}
            >
             {props.suffix_icon}
            </div>
          )}
        </label>
      </div>
    </>
  );

  function buildStyle() {
    return "border-none flex w-full text-inputText   shrink-0  bg-inputBackground rounded-lg  ";
  }
}
