import classNames from "classnames";
import React from "react";
import { MdOutlineCancel } from "react-icons/md";

export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  return (
    <div className="flex relative ">
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min`}
        className={classNames(
          "p-2 rounded-md bg-white focus:ring-0 border-none text-xs    outline-none  focus:bg-blue-50",
          {
            "bg-emerald-200": filterValue[0],
          }
        )}
        style={{
          width: "70px",
          marginRight: "0.2rem",
        }}
      />
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        className={classNames(
          "p-2 rounded-md bg-white focus:ring-0 border-none text-xs   outline-none  focus:bg-blue-50",
          {
            "bg-emerald-200": filterValue[1],
          }
        )}
        placeholder={`Max`}
        style={{
          width: "70px",
          marginLeft: "0.2rem",
        }}
      />

      {(filterValue[0] || filterValue[1]) && (
        <div
          className="absolute top-1/2 cursor-pointer -right-5 transform -translate-y-1/2 rounded-full bg-slate-100 text-slate-700"
          onClick={() => setFilter([undefined,undefined])}
        >
          <MdOutlineCancel className="w-4 h-4" /> 
        </div>
      )}
    </div>
  );
}
