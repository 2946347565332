import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

export default function UserDetailProjectGraph() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const labels = ["Tedak", "Akgün", "Akyön", "X", "Y", "Z"];
  const data = {
    labels,
    datasets: [
      {
        label: "İş geliştirme",
        data: labels.map(() => randomNumberInRange(1, 100)),
        backgroundColor: "rgb(80, 80, 132, 0.8)",
      },
      {
        label: "Proje Çizimi",
        data: labels.map(() => randomNumberInRange(1, 100)),
        backgroundColor: "rgb(200, 190, 192, 0.8)",
      },
      {
        label: "Pano",
        data: labels.map(() => randomNumberInRange(1, 100)),
        backgroundColor: "rgb(53, 162, 235, 0.8)",
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Proje Analizi",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return (
    <div className="">
      <Bar options={options} data={data} />
    </div>
  );
  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
