import CustomLottie from "components/Lottie";
import PreviewImage from "components/PreviewImage";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import noData from "./../../asset/lottie/no-work";

export default function HomeActiceWorks() { 

  const {
    data: works,
    isLoading,
    error,
  } = useFetchData(ApiConstants.ACTIVE_WORKS);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  console.log(error);

  const getTimeDiff = (startedDate) => {
    const minute = (new Date() - new Date(startedDate)) / (60 * 1000);
    return `${
      Math.floor(minute / 60) > 0 ? `${Math.floor(minute / 60)} sa` : ""
    } ${Math.floor(minute % 60)} dk`;
  };
  console.log(works);
  return (
    <div className="flex flex-col p-2 bg-white w-1/3  ">
      <div className=" text-sm text-slate-400">
        Şuan çalışanlar {works && "- " + works.length}
      </div>
      {works == null ? (
        <div className="flex flex-col    w-full  items-center justify-center pt-10 ">
          <CustomLottie animationData={noData} size={200} />
          <div className="text-lg font-thin text-slate-400">
            Bugün çalışma yok
          </div>
        </div>
      ) : (
        <div className="p-4 overflow-auto">
          {works.map((v, index) => {
            return (
              <div className="flex  flex-col ">
                <div className="flex items-center gap-x-2">
                  <PreviewImage
                    className=" rounded-full h-14 w-14 p-1"
                    file={null}
                    src={v.userProfile}
                  />
                  <div className="flex flex-col w-full">
                    <div className="text-sm ">{v.user}</div>
                    <div className="text-sm text-slate-500">{v.panelName}</div>
                    <div className="text-sm text-slate-500">
                      {v.divisionName}
                    </div>
                    <div className="text-sm text-slate-500 flex justify-end">
                      {getTimeDiff(v.startedDate)}
                    </div>
                  </div>
                </div>
                <div className="   h-[1px] w-full  bg-gray-100 my-2"></div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
