import React from "react";
import EditUser from "./components/edit_user";
import { Toaster } from "react-hot-toast"; 
import UsersBody from "./components/body";

export default function UsersPage() {
  return (
    <div className="flex   px-4    w-full  relative">
      <div className=" w-full   rounded-md">
        <UsersBody />
      </div>
      <span className="px-3"></span>
      <EditUser />
      <Toaster position="top-right" />
    </div>
  );
}
