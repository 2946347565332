import classNames from "classnames";
import React from "react";
import { MdOutlineCancel } from "react-icons/md";

export default function DateColumnFilter({
  column: { filterValue, setFilter },
}) {
  return (
    <div className=" relative flex  ">
      <input
        value={filterValue || ""}
        type="date"
        className={classNames(
          "p-2 text-sm rounded-md  bg-white outline-none border-none  selection:text-red-400  focus:ring-0  focus:bg-blue-50 ",
          {
            "bg-emerald-200": filterValue,
          }
        )}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old) => (val ? val : undefined));
        }}
      />
      {filterValue && (
        <div
          className="absolute top-1/2 cursor-pointer -right-5 transform -translate-y-1/2 rounded-full bg-slate-100 text-slate-700"
          onClick={() => setFilter(undefined)}
        >
          <MdOutlineCancel className="w-4 h-4" />
        </div>
      )}
    </div>
  );
}
