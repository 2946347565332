import { ResponsiveCalendar } from "@nivo/calendar";
import { CustomListBoxWithoutFormik } from "components/listbox";
import { useState } from "react";
 
export default function CustomNivoCalendar({ data,date,onClick }) {
  return (
    <>
      <ResponsiveCalendar
        data={data}
        from={date ? `${date}` : data[data.length - 1].day}
        to={date ? `${date}` : data[0].day}
        emptyColor="#eeeeee"
        colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
        yearSpacing={40}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        onClick={onClick}
        legends={[
          {
            anchor: "bottom-right",
            direction: "row",
            translateY: 36,
            itemCount: 4,
            itemWidth: 42,
            itemHeight: 36,
            itemsSpacing: 14,
            itemDirection: "right-to-left",
          },
        ]}
      />
    </>
  );
}
