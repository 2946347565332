import React from "react";  
import EditDivision from "./components/edit_division"; 
import DivisionTable from "./components/table"; 

export default function DivisionsPage() {
  return (
    <div className="flex   px-4 gap-x-6    w-full  relative">
      <div className=" w-full  rounded-md">
        <DivisionTable/>
      </div>
      <div className="w-1/3">
        <EditDivision/>
        
      </div>
    </div>
  );
}
