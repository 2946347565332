import moment from "moment";
import _ from "lodash";

export class AppUtils {
  
  static formatDate = (dt) => {
    moment.locale("tr");
    return moment(dt).format("DD-MM-YYYY");
  };
  static formatClock = (dt) => {
    moment.locale("tr");
    return moment(dt).format("HH:mm");
  };
  static formatDateAndClock = (dt) => {
    moment.locale("tr");
    return moment(dt).format("DD-MM-YYYY HH:mm");
  };
  static groupBy = function (data, property) {
    return _(data)
      .groupBy(property)
      .map((value, key) => ({ key, value }))
      .value();
  };

  static dayOfWeek = (dt) => { 
    return moment(dt).isoWeekday();
  };
  static weekDay = (day) => { 
    return moment().isoWeekday(day).toDate();
  };
  static days = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'];
}
