import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import PanelService from "services/panel_service";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { setPanels } from "store/panel";

function DashboardWorkingUsers() {
  const dispatch = useDispatch();

  const { data: workingUsers } = useFetchData(ApiConstants.ACTIVE_WORKS);

  useEffect(() => {
    if (workingUsers) {
      const panelIDs = new Set(workingUsers.map((v) => v.panelID));
      const query = `?panelIDs=${[...panelIDs].join(
        ","
      )}&withDetailsWithWorks=true`;
      PanelService.getAll({ query }).then((res) => {
        dispatch(setPanels(res));
      });
    }
  }, [workingUsers, dispatch]);

  const getTimeDiff = (startedDate) => {
    const minute = (new Date() - new Date(startedDate)) / (60 * 1000);
    return `${
      Math.floor(minute / 60) > 0 ? `${Math.floor(minute / 60)} sa` : ""
    } ${Math.floor(minute % 60)} dk`;
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 6000,
    cssEase: "linear",
  };

  if (!workingUsers) return null;

  return (
    <div style={{ height: "100%" }}>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ pb: 2 }}
      >
        <div className="text-red-600 font-bold text-lg">
          Şuan Çalışanlar - {workingUsers.length}
        </div>
      </Grid>

      <div className="slider-container">
        <Slider {...settings}>
          {workingUsers.map((v) => (
            <div key={v.panelID} className="-32 w-32">
              <div className="flex justify-center h-full  items-end border-1 border-solid rounded">
                {v.userProfile ? (
                  <img
                    src={v?.userProfile ?? ""}
                    alt={v.userProfile}
                    style={{
                      width: "40%",
                      height: "auto",
                      display: "block",
                      margin: "0 auto",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div className="bg-red-50 text-red-700 text-3xl p-6 h-32 w-32 flex items-center relative justify-center rounded">
                    {(() => {
                      const [firstName, lastName] = v.user.split(" ");

                      const firstInitial = firstName.charAt(0).toUpperCase();
                      const lastInitial = lastName.charAt(0).toUpperCase();

                      return `${firstInitial}${lastInitial}`;
                    })()}
                  </div>
                )}
              </div>
              <div className="text-red-600 font-bold text-lg pt-1 text-center">
                {v.divisionName}
              </div>
              <div className="text-gray-600 font-bold text-sm text-center">
                {v.panelName}
              </div>
              <div className="text-gray-500 font-bold text-lg text-center">
                {getTimeDiff(v.startedDate)}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default DashboardWorkingUsers;
