import CustomToolTip from "components/tooltip";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import { useState } from "react";
import PanelDetailPieGraph from "./pie_graph";
import { CustomProgressBar } from "components/ProgressBar";

export default function PanelDetailReportGroupByUserTable() {
  const selectedWorks = useSelector((state) => state.workSlice.selectedWorks);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (selectedWorks) {
      const totalUsedTime = selectedWorks.reduce(
        (partialSum, a) => partialSum + a.usedTime,
        0
      );
      const totalTime = selectedWorks[0].totalTime;
      const percentTime = ((totalUsedTime / totalTime) * 100).toFixed(0);
      const totalUsedBudget = selectedWorks.reduce(
        (partialSum, a) => partialSum + a.usedBudget,
        0
      );
      const totalBudget = selectedWorks[0].totalBudget;
      const percentBudget = ((totalUsedBudget / totalBudget) * 100).toFixed(0);
      setData({
        ...selectedWorks,
        totalUsedTime,
        totalUsedBudget,
        percentTime,
        percentBudget,
        totalBudget,
        totalTime,
      });
    }
  }, [selectedWorks]);

  return (
    <>
      {selectedWorks && data && (
        <div className="flex mt-6 gap-x-4 ">
          <div className="flex   flex-col bg-white shadow p-4 rounded-lg min-w-[500px]">
            <div className="flex">
              <div>
                {detailCol("Pano Adı", selectedWorks[0].panelName)}
                {detailCol("Pano Kodu", selectedWorks[0].panelCode || "0")}
                {detailCol("Proje", selectedWorks[0].projectName)}
                {detailCol("Müşteri", selectedWorks[0].companyName)}
                {detailCol("Son Müşteri", selectedWorks[0].lastCompanyName)}
              </div>
            </div>
            <div className="flex flex-col gap-y-1  mt-4  ">
              <div className="text-sm mx-2 text-slate-400">Bütçe</div>
              <CustomToolTip
                content={` ${data.totalUsedBudget} / ${data.totalBudget} ₺`}
              >
                <CustomProgressBar value={data.percentBudget} color="#34d399" />
              </CustomToolTip>
              <div className="text-sm mx-2 text-slate-400">Zaman</div>
              <CustomToolTip
                content={` ${data.totalUsedTime} / ${data.totalTime} sa`}
              >
                <CustomProgressBar value={data.percentTime} color="#60a5fa" />
              </CustomToolTip>
 
            </div>
          </div>
          <div className="flex justify-center w-full">
            <PanelDetailPieGraph />
          </div>
        </div>
      )}
    </>
  );
}
function detailCol(key, val) {
  return (
    <div className="flex m-1 my-2 ">
      {/* <div className="bg-slate-100 w-0.5 mx-1 rounded-lg"></div> */}
      <div className="flex flex-col pl-2">
        <div className="text-slate-400  text-sm">{key}</div>
        <div className="text-slate-500  text-base font-medium">{val}</div>
      </div>
    </div>
  );
}
