import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  division: null,
  divisions: null,
};

const divisionSlice = createSlice({
  name: "division",
  initialState,
  reducers: {
    setDivision: (state, action) => {
      state.division = action.payload;
    },
    addDivision: (state, action) => {
      state.divisions.push(action.payload);
    },
  },
});

export const { setDivision } = divisionSlice.actions;
export default divisionSlice.reducer;
