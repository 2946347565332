import { CustomProgressBar } from "components/ProgressBar";
import Table from "components/Table";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { OverflowCell } from "components/Table/components/overflow";


function DashboardPanels() {
  const { panels } = useSelector((state) => state.dashboardSlice);
  useEffect(() => {}, [panels]);

  const columns = useMemo(() => {
    const col = [
      {
        Header: "Panel Adı",
        accessor: "panelName",
        canGroupBy: false,
      },
      {
        Header: "Panel Kodu",
        accessor: "panelCode",
        canGroupBy: false,
      },
      {
        Header: "Son Müşteri",
        accessor: "companyName",
        canGroupBy: false,
        Cell: OverflowCell,
      },
      {
        Header: "Zaman Yüzdesi",
        accessor: "timePercent",
        Cell: ({ row }) => {
          const timePercent = parseInt(
            ((row.original.usedTime ?? 0) / row.original.totalTime) * 100
          );
          return <CustomProgressBar value={timePercent} color="#34d399" />;
        },
        canGroupBy: false,
      },
    ];
    return col;
  }, [panels]);

  const dotColor = ["success", "info", "error"];

  if (!panels) return null;

  console.log("**********************");
  console.log(panels);
  console.log("**********************");

  return (
    <Table
      className="h-full relative w-full"
      columns={columns}
      data={panels}
      pageSize={50}
      exportExcel={false}
      fileName="Panolar"
      enableFilters={false}
      enablePageNumbers={false}
    />
  );
}

export default DashboardPanels;
