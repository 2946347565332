import React, { Fragment, useEffect, useState } from "react";
import { useField } from "formik";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import classNames from "classnames";
export const CustomListBox = ({
  label,
  values,
  multiple = false,
  ...probs
}) => {
  const [field, meta, helpers] = useField(probs);
  const selected = field.value;

  const [query, setQuery] = useState("");

  const filteredValues =
    query === ""
      ? values
      : values.filter((value) =>
          value
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(
              query
                .split(",")
                [query.split(",").length  - 1].toLowerCase()
                .replace(/\s+/g, "")
            )
        );

  values.sort((a, b) => {
    if (field.value.includes(a)) return -1;
    else return 1;
  });
  return (
    <div className="rounded-lg releative ">
      <Combobox
        multiple={multiple}
        disabled={probs.disabled}
        value={selected ?? ""}
        onChange={(val) => helpers.setValue(val)}
      >
        <div className="relative rounded-lg  ">
          <div className="relative ring-transparent	 text-inputText    ">
            <Combobox.Input
              required={probs.required ?? false}
              className={classNames(
                "border-none  flex w-full pr-10 truncate  shrink-0 border-red-500 focus:shadow-[0_0_0_0.2rem_rgba(255,113,113,0.5)]  focus:ring-inherit bg-inputBackground rounded-lg",
                {
                  "pt-[1.32rem]": selected || query,
                  "bg-red-50 select-none pointer-events-none w-full ":
                    probs.disabled,
                }
              )}
              displayValue={(value) =>value}
              onChange={(event) => setQuery(event.target.value)}
            />
            <small
              className={classNames({
                "absolute  left-[13px] cursor-text pointer-events-none text-gray-400 -translate-y-1/2 transition-all ": true,
                "text-sm top-1/2": !selected || query,
                "text-[13px] top-4": selected || query,
              })}
            >
              {   (field.value.length == 0 || !multiple) ? label : label +` - ${field.value.length}`}
            </small>
            <Combobox.Button
              onClick={() => {
                setQuery("  ");
              }}
              className=" absolute  inset-y-0 right-2 flex items-center "
            >
              <SelectorIcon
                className="h-5 w-5 text-gray-400 "
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className=" absolute  z-50 mb-10 rounded-lg mt-1 max-h-60 w-full overflow-auto  bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredValues.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-red-500">
                  Bulunamadı.
                </div>
              ) : (
                filteredValues.map((value) => (
                  <Combobox.Option
                    key={value}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4  ${
                        field.value.includes(value) || active
                          ? "bg-red-50 font-bold text-red-500"
                          : "text-red-500 "
                      }`
                    }
                    value={value}
                  >
                    {({ active }) => {
                      return (
                        <>
                          <span
                            className={`block truncate  ${
                              selected === value
                                ? "font-medium "
                                : "font-normal"
                            }`}
                          >
                            {value}
                          </span>
                          {field.value.includes(value) ? (
                            <span
                              className={`absolute group-target:bg-red-400 inset-y-0 left-0 flex items-center pl-3  ${
                                active ? " " : "text-red-500 "
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};
