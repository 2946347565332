import ApiConstants from "./api";
import { postData } from "./axios_helper";

class AuthServices {
  static async login(data) {
    const res = await postData(ApiConstants.login, data);
    return res;
  }
  static async register(data) {
    const res = await postData(ApiConstants.register, data);
    return res;
  }
}

export default AuthServices;
