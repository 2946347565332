import ApiConstants from "./api";
import service, { deleteData, postData, putData } from "./axios_helper";
class UsersService {
  static async update(id, data) {
    const res = await putData(ApiConstants.USERS + "/" + id, data);
    return res;
  }
  static async delete(id) {
    const res = await deleteData(ApiConstants.USERS + "/" + id);
    return res;
  }

  static async uploadProfile(id, file) {
    const imageData = new FormData();
    imageData.append("image", file);
    service.defaults.headers["path"] = id;
    const res = await postData(ApiConstants.addProfilePicture, imageData);
    return res.data;
  }
}

export default UsersService;
