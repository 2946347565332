import {
  Chip,
  List,
  ListItem,
  ListItemText,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { EdgeLabelRenderer, BaseEdge, getSmoothStepPath } from "reactflow";

var _ = require("lodash");

function formatTime(minutes) {
  if (minutes < 60) {
    return `${minutes} d`;
  } else if (minutes < 1440) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} s ${remainingMinutes} d`;
  } else {
    const days = Math.floor(minutes / 1440);
    const hours = Math.floor((minutes % 1440) / 60);
    const remainingMinutes = minutes % 60;
    return `${days} g ${hours} s ${remainingMinutes} d`;
  }
}
function getMinutesBetweenDates(startDate, endDate) {
  const diff = endDate.getTime() - startDate.getTime();

  return diff / 60000;
}

const getEdgeDetails = ({ works, source, target }) => {
  const sourceList = source.split("-");
  const targetList = target.split("-");
  const sourceDivisionId = sourceList[sourceList.length - 1];
  const targetDivisionId = targetList[targetList.length - 1];
  if (sourceDivisionId === "0" || targetDivisionId === "1")
    return { mean: undefined, standardDeviation: undefined };
  var minuteDiff = [];
  for (let index = 0; index < works.length; index++) {
    const work = works[index];
    if (work.divisionPattern.length > 1) {
      const sourceDate = work.divisions.find(
        (e) => e.divisionID === sourceDivisionId
      ).startedDate;
      const targetDate = work.divisions.find(
        (e) => e.divisionID === targetDivisionId
      ).startedDate;

      minuteDiff.push(
        parseInt(
          getMinutesBetweenDates(new Date(sourceDate), new Date(targetDate))
        )
      );
    }
  }
  //// For mean and standard deviation by started date
  var meanByStartedDate = _.mean(minuteDiff);
  const squaredDiff1 = minuteDiff.map((p) =>
    Math.pow(p - meanByStartedDate, 2)
  );

  const sumOfSquaredDiff1 = _.sum(squaredDiff1);
  const variance1 = sumOfSquaredDiff1 / (works.length - 1);
  var standardDevByStartedDate = works.length === 1 ? 0 : Math.sqrt(variance1);
  meanByStartedDate = formatTime(parseInt(meanByStartedDate));
  standardDevByStartedDate = formatTime(parseInt(standardDevByStartedDate));

  //// For mean and standard deviation by used time
  const usedTime = works.map((p) => p.usedTime);
  var meanByUsedTime = _.mean(usedTime);
  const squaredDiff2 = usedTime.map((p) => Math.pow(p - meanByUsedTime, 2));

  const sumOfSquaredDiff2 = _.sum(squaredDiff2);
  const variance2 = sumOfSquaredDiff2 / (works.length - 1);
  var standardDevByUsedTime = works.length === 1 ? 0 : Math.sqrt(variance2);
  meanByUsedTime = formatTime(parseInt(meanByUsedTime));
  standardDevByUsedTime = formatTime(parseInt(standardDevByUsedTime));

  //// For mean and standard deviation by used budget
  const usedBudget = works.map((p) => p.usedBudget);
  var meanByUsedBudget = _.mean(usedBudget);
  const squaredDiff3 = usedBudget.map((p) => Math.pow(p - meanByUsedBudget, 2));
  const sumOfSquaredDiff3 = _.sum(squaredDiff3);
  const variance3 = sumOfSquaredDiff3 / (works.length - 1);
  var standardDevByUsedBudget = works.length === 1 ? 0 : Math.sqrt(variance3);
  meanByUsedBudget = parseInt(meanByUsedBudget);
  standardDevByUsedBudget = parseInt(standardDevByUsedBudget);

  return {
    meanByStartedDate,
    standardDevByStartedDate,
    meanByUsedTime,
    standardDevByUsedTime,
    meanByUsedBudget,
    standardDevByUsedBudget,
  };
};

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const { source, target } = props;
  const { works } = data;

  const {
    meanByStartedDate,
    standardDevByStartedDate,
    meanByUsedTime,
    standardDevByUsedTime,
    meanByUsedBudget,
    standardDevByUsedBudget,
  } = getEdgeDetails({ works, source, target });

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-105%,  -120%) translate(${targetX}px,${targetY}px)`,
            background: "#FFCF96",
            padding: 3,
            pointerEvents: "all",
            borderRadius: 2,
            fontSize: 6,
            fontWeight: 600,
          }}
          onClick={handleClick}
          className="hover:bg-slate-400 cursor-context-menu "
        >
          {meanByStartedDate}
        </div>
        <div
          style={{
            position: "absolute",
            transform: `translate(-105%,  20%) translate(${targetX}px,${targetY}px)`,
            background: "#B0D9B1",
            padding: 3,
            borderRadius: 2,
            fontSize: 6,
            fontWeight: 600,
          }}
        >
          {standardDevByStartedDate}
        </div>
      </EdgeLabelRenderer>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List dense={true} sx={{ width: 400 }}>
          <ListItem
            secondaryAction={
              <Stack direction="row" spacing={1}>
                <Chip
                  label={meanByStartedDate}
                  color="warning"
                  variant="filled"
                  sx={{
                    fontSize: 9,
                    fontWeight: 600,
                    backgroundColor: "#FFCF96",
                    color: "black",
                  }}
                />
                <Chip
                  label={standardDevByStartedDate}
                  color="success"
                  variant="filled"
                  sx={{
                    fontSize: 9,
                    fontWeight: 600,
                    backgroundColor: "#B0D9B1",
                    color: "black",
                  }}
                />
              </Stack>
            }
          >
            <ListItemText primary="B. zam. göre" />
          </ListItem>
          <ListItem
            secondaryAction={
              <Stack direction="row" spacing={1}>
                <Chip
                  label={meanByUsedTime}
                  color="warning"
                  variant="filled"
                  sx={{
                    fontSize: 9,
                    fontWeight: 600,
                    backgroundColor: "#FFCF96",
                    color: "black",
                  }}
                />
                <Chip
                  label={standardDevByUsedTime}
                  color="success"
                  variant="filled"
                  sx={{
                    fontSize: 9,
                    fontWeight: 600,
                    backgroundColor: "#B0D9B1",
                    color: "black",
                  }}
                />
              </Stack>
            }
          >
            <ListItemText primary="Çal. sa. göre" />
          </ListItem>{" "}
          <ListItem
            secondaryAction={
              <Stack direction="row" spacing={1}>
                <Chip
                  label={meanByUsedBudget}
                  variant="filled"
                  color="warning"
                  sx={{
                    fontSize: 9,
                    fontWeight: 600,
                    backgroundColor: "#FFCF96",
                    color: "black",
                  }}
                />
                <Chip
                  label={standardDevByUsedBudget}
                  color="success"
                  variant="filled"
                  sx={{
                    fontSize: 9,
                    fontWeight: 600,
                    backgroundColor: "#B0D9B1",
                    color: "black",
                  }}
                />
              </Stack>
            }
          >
            <ListItemText primary="Büt. göre" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default CustomEdge;
