import classNames from "classnames";
import CustomToolTip from "components/tooltip";

export function OverflowCell({ value = "" }) {
  return (
    <>
      <CustomToolTip content={(value && value.length > 15) ? value : null} > 
        <div
          className={classNames("max-w-[120px] truncate ", {
            " cursor-help":(value && value.length > 15) ? true : false,
          })} 
        >
          {value}
        </div>
      </CustomToolTip>
    </>
  );
}
