import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  work: null,
  works: null,
  selectedWorks: null,
};

const workSlice = createSlice({
  name: "works",
  initialState,
  reducers: {
    setWork: (state, action) => {
      state.work = action.payload;
    },
    setWorks: (state, action) => {
      state.works = action.payload;
    },
    setSelectedWorks: (state, action) => {
      state.selectedWorks = action.payload;
    },
  },
});

export const { setWork, setWorks,setSelectedWorks } = workSlice.actions;
export default workSlice.reducer;
