import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css"
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import "reactflow/dist/style.css";
import "./style.css";
import 'devextreme/dist/css/dx.light.css';
import "./Custom.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
document.title = "Tedak Admin";
root.render( 
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider> 
);
