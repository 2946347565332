import PageNotFound from "components/404";
import HomeLayout from "layout/home_layout";
import CompainesPage from "pages/companies";
import DivisionsPage from "pages/divisions";
import HomePage from "pages/home";
import AdminLoginPage from "pages/login";
import PanlesPage from "pages/panels";
import ProjectsPage from "pages/projects";
import UsersPage from "pages/users";
import PrivateRoute from "./routes/PrivateRoute";
import ReportsPage from "pages/reports";
import WorkingHours from "pages/working_hours";
import ProcessMining from "pages/processMining";
import DashboardScreen from "pages/dashboard";

const routes = [
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/",
    children: [
      {
        index: true,
        element: <AdminLoginPage />,
      },
    ],
  },
  {
    path: "/admin/login",
    children: [
      {
        index: true,
        element: <AdminLoginPage />,
      },
    ],
  },

  {
    path: "/admin/dashboard",
    children: [
      {
        index: true,
        element: <DashboardScreen />,
      },
    ],
  },
  
  {
    path: "/admin",
    element: <HomeLayout />,
    auth: true,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "reports",
        element: <ReportsPage />,
      },
      {
        path: "processMining",
        element: <ProcessMining />,
      },
      {
        path: "settings/users",
        element: <UsersPage />,
      },
      {
        path: "settings/companies",
        element: <CompainesPage />,
      },
      {
        path: "settings/divisions",
        element: <DivisionsPage />,
      },
      {
        path: "settings/projects",
        element: <ProjectsPage />,
      },
      {
        path: "settings/panels",
        element: <PanlesPage />,
      },
      {
        path: "settings/working-hours",
        element: <WorkingHours />,
      },
    ],
  },
];

const authCheck = (routes) =>
  routes.map((route) => {
    if (route?.auth) {
      route.element = <PrivateRoute>{route.element}</PrivateRoute>;
    }
    if (route?.children) {
      route.children = authCheck(route.children);
    }
    return route;
  });
export default authCheck(routes);
