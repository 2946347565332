import Loading from "components/Loading";
import Table from "components/Table";
import { useMemo } from "react";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import { setDivision } from "store/division";

export default function DivisionTable() { 
  const dispatch = useDispatch(); 

  const {
    data: divisions,
    isLoading,
    error,
  } = useFetchData(ApiConstants.DIVISIONS);

  const columns = useMemo(
    () => [
      {
        Header: "Bölüm Adı",
        accessor: "divisionName",
        canGroupBy: false,
      },
      {
        Header: " ",
        accessor: null,
        Cell: EditBtn,
        canGroupBy: false,
      },
    ],
    [divisions]
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  return divisions == null ? (
    <Loading />
  ) : (
    <div>
      <div className="p-2 text-slate-500 font-light">
        {
          "Uyarı: Bölüm isimlerinde nokta kullanmayınız. Örn Kapı Vb. Montaj => Kapı Vb Montaj"
        }
      </div>
      <Table
        className="h-full"
        columns={columns}
        data={divisions}
        pageSize={13}
      />
    </div>
  );
  function EditBtn({ value, column, row }) {
    return (
      <div className="  flex justify-end">
        <button
          onClick={() => dispatch(setDivision(row.original))}
          className="bg-red-100 text-red-500 p-1.5 rounded-md  justify-end flex "
        >
          <FiEdit />
        </button>
      </div>
    );
  }
}
