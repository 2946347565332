import Loading from "components/Loading";
import CustomLottie from "components/Lottie";
import Table from "components/Table";
import DateColumnFilter from "components/Table/Filter/DateFilter";
import { NumberRangeColumnFilter } from "components/Table/Filter/NumberRange";
import { DateColumn } from "components/Table/components/date";
import { OverflowCell } from "components/Table/components/overflow";
import { CustomListBoxWithoutFormik } from "components/listbox";
import { Checkbox } from "primereact/checkbox";
import { useEffect, useMemo, useState } from "react";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import noData from "../../../asset/lottie/table-no-data";
import WorkTableDatePicker from "../component/date_picker";

export default function WorkReportsTable({ worksData }) {
  const [data, setData] = useState(null);
  const [isDaily, setIsDaily] = useState(false);
  const [isDevelop, setIsDevelop] = useState(false);
  const [calendarYear, setCalenderYear] = useState(
    new Date().getFullYear().toString()
  );
  const monthMapping = {
    Ocak: 0,
    Şubat: 1,
    Mart: 2,
    Nisan: 3,
    Mayıs: 4,
    Haziran: 5,
    Temmuz: 6,
    Ağustos: 7,
    Eylül: 8,
    Ekim: 9,
    Kasım: 10,
    Aralık: 11,
  };

  const [calendarMonth, setCalendarMonth] = useState(new Date().getMonth());
  const [yearsList, setYearsList] = useState([]);

  const {
    data: worksFromDb,
    isLoading,
    error,
  } = useFetchData(
    `${ApiConstants.WORKS}${
      calendarYear === "Tümü"
        ? ""
        : `?year=${calendarYear}&month=${calendarMonth}`
    }`
  );

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2022; year--) {
      years.push(year.toString());
    }
    years.push("Tümü");
    setYearsList(years);
  }, []);

  const works = worksData ? worksData : worksFromDb;

  useEffect(() => {
    if (works) {
      var _data = works.filter((v) => v.endedDate !== null);
      _data = _data.map((v) => {
        const startedDateOnlyDate = v.startedDate.slice(0, 10);
        const endedDateOnlyDate = v.endedDate.slice(0, 10);
        return { ...v, startedDateOnlyDate, endedDateOnlyDate };
      });
      setData(_data);
    }
  }, [works]);

  useEffect(() => {
    if (calendarYear === "Tümü") {
      setCalendarMonth(null); // Ay seçimini temizle
    } else {
      setCalendarMonth(new Date().getMonth()); // Varsayılan ay seçimi
    }
  }, [calendarYear]);

  const DateColumnCell = ({ value, column, row }) => {
    return isDevelop ? (
      <div>{WorkTableDatePicker({ value, column, row })}</div>
    ) : (
      DateColumn({ value, column, row })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Personel",
        accessor: "user",
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Personel`,
      },
      {
        Header: "Bölüm",
        accessor: "divisionName",
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Bölüm`,
      },
      {
        Header: "Proje",
        accessor: "projectName",
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Proje`,
      },
      {
        Header: "Pano Adı",
        accessor: "panelName",
        Cell: OverflowCell,
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Pano`,
      },
      {
        Header: "Pano Kodu",
        accessor: "panelCode",
        Cell: OverflowCell,
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Pano`,
      },
      {
        Header: "Başlangıç",
        accessor: isDaily ? "startedDateOnlyDate" : "startedDate",
        dateFormat: isDaily ? "DD-MM-YYYY" : "DD-MM-YYYY HH:mm",
        Cell: DateColumnCell,
        Filter: DateColumnFilter,
        filter: "fromDate",
        type: "date",
      },
      {
        Header: "Bitiş",
        accessor: isDaily ? "endedDateOnlyDate" : "endedDate",
        dateFormat: isDaily ? "DD-MM-YYYY" : "DD-MM-YYYY HH:mm",
        Cell: DateColumnCell,
        Filter: DateColumnFilter,
        filter: "toDate",
        type: "date",
      },
      {
        Header: "Toplam Süre (dk)",
        accessor: "usedTime",
        Filter: NumberRangeColumnFilter,
        filter: "between",
        aggregate: "sum",
        Aggregated: ({ value }) => `${value}`,
      },
      {
        Header: "Bütçe (₺)",
        accessor: "usedBudget",
        Filter: NumberRangeColumnFilter,
        filter: "between",
        aggregate: "sum",
        Aggregated: ({ value }) => `${value} `,
      },
    ],
    [works, isDaily, isDevelop]
  );

  const handleMonthChange = (month) => {
    setCalendarMonth(monthMapping[month]);
  };

  if (error) return <div>Error...</div>;

  return (
    <>
      <div className="flex justify-end">
        <div className="flex justify-end pr-10 mb-3">
          {calendarYear !== "Tümü" && (
            <CustomListBoxWithoutFormik
              label={"Ay"}
              selected={Object.keys(monthMapping).find(
                (key) => monthMapping[key] === calendarMonth
              )}
              setSelected={handleMonthChange}
              values={Object.keys(monthMapping)}
            />
          )}
        </div>
        <div className="flex justify-end pr-10 mb-3">
          <CustomListBoxWithoutFormik
            label={"Yıl"}
            selected={calendarYear}
            setSelected={(year) => setCalenderYear(year)}
            values={yearsList}
          />
        </div>
      </div>

      {isLoading && <Loading />}
      {Object.keys(data || []).length === 0 && (
        <div className="flex flex-col h-max justify-center  items-center text-slate-500 pt-20 ">
          <CustomLottie animationData={noData} />
          <div className="flex text-2xl  ">Kayıt bulunamadı...</div>
        </div>
      )}

      {!isLoading && Object.keys(data || []).length > 0 && (
        <Table
          className="h-full"
          columns={columns}
          exportExcel
          data={data}
          pageSize={50}
          fileName="İş_Kayıtları"
          Header={
            <div className="flex w-full justify-end ">
              <div className="flex justify-end pr-6 items-center text-xs text-slate-600 gap-x-2 font-medium">
                <Checkbox
                  onChange={(e) => setIsDaily(e.checked)}
                  checked={isDaily}
                  />
                Günlük Veriler
              </div>
              <div className="flex justify-end pr-6 items-center text-xs text-slate-600 gap-x-2 font-medium">
                <Checkbox
                  onChange={(e) => setIsDevelop(e.checked)}
                  checked={isDevelop}
                  />
                Gelişmiş Seçenekler
              </div>
            </div>
          }
          />
      )}
    </>
  );
}
