import React from "react";
import HomeEvents from "./events";
import HomePersonelHeader from "./personel_header";
import HomeMap from "./map";
import HomeWorkHours from "./workHours";
import HomeLastProject from "./last_projects";
import HomeActiceWorks from "./active_works";
export default function HomePage() {
  return (
    <>
      <div className="flex   p-4 relative bg-slate-50 gap-x-2 overflow-auto h-full ">
        <div className="w-full flex flex-col gap-y-2 h-[50%]">
          <div className="w-full flex pb-4 h-96 gap-x-2">
            <HomeMap />
            <HomeWorkHours />
          </div>
          <div className="w-full flex  h-full gap-x-2 ">
            <HomeLastProject />
            <HomeActiceWorks />
          </div>
        </div>
        <div className="flex w-5/12 flex-col gap-y-3  ">
          <HomePersonelHeader />
          <HomeEvents />
        </div>
      </div>
    </>
  );
}
