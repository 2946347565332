import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  panel: null,
  panels: null,
};

const panelSlice = createSlice({
  name: "panel",
  initialState,
  reducers: {
    setPanel: (state, action) => {
      state.panel = action.payload;
    },
    setPanels: (state, action) => {
      state.panels = action.payload;
    },
  },
});

export const { setPanel,setPanels } = panelSlice.actions;
export default panelSlice.reducer;
