import ApiConstants from "./api";
import { deleteData, fetchData, postData, putData } from "./axios_helper";
class ProjectService {
  static async getAll() {
    const res = await fetchData(ApiConstants.PROJECTS);
    return res;
  }
  static async add(data) {
    const res = await postData(ApiConstants.PROJECTS, data);
    return res;
  }

  static async update(id, data) {
    const res = await putData(ApiConstants.PROJECTS + "/" + id, data);
    return res;
  }

  static async delete(id) {
    const res = await deleteData(ApiConstants.PROJECTS + "/" + id);
    return res;
  }
}

export default ProjectService;
