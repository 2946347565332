import axios from 'axios';
import useSWR from 'swr';
import ApiConstants from './api';

const service = axios.create({
    baseURL: ApiConstants.BASEURL,
    headers: {
        "Content-Type": 'application/json',
    },
});

service.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("tdk_acces_token")

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    (response) => response,
    (error) => {
        const errResponse = error?.response?.data;

        if (errResponse?.message) {
            error.message = errResponse.message;
        }

        return Promise.reject(error);
    }
);

export const fetchData = async (url) => {
    try {
        const response = await service.get(url);

        return response.data;
    } catch (error) {
        console.error('Fetch Data Error:', error);
        throw error;
    }
};

export const useFetchData = (url) => {
    const { data, error, mutate, isLoading, isValidating } = useSWR(url, fetchData);

    return {
        data,
        error,
        mutate,
        isLoading,
        isValidating
    };
};

export const postData = async (url, data) => {
    try {
        const response = await service.post(url, data);

        return response.data;
    } catch (error) {
        console.error('Post Data Error:', error);
        throw error;
    }
};

export const putData = async (url, data) => {
    try {
        const response = await service.put(url, data);

        return response.data;
    } catch (error) {
        console.error('Put Data Error:', error);
        throw error;
    }
};

export const patchData = async (url, data) => {
    try {
        const response = await service.patch(url, data);

        return response.data;
    } catch (error) {
        console.error('Patch Data Error:', error);
        throw error;
    }
};

export const deleteData = async (url) => {
    try {
        const response = await service.delete(url);

        return response.data;
    } catch (error) {
        console.error('Delete Data Error:', error);
        throw error;
    }
};

// File upload
export const uploadFile = async (url, files) => {
    const formData = new FormData();

    files.forEach((file) => {
        formData.append("media", file);
    });

    try {
        await service.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    } catch (error) {
        console.error('Upload File Error:', error);
        throw error;
    }
};

export default service;
