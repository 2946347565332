import CustomDialog from "components/dialog";
import CustomButton from "components/Form/Button";
import Loading from "components/Loading";
import PreviewImage from "components/PreviewImage";
import Table from "components/Table";
import QRCode from "qrcode.react";
import { useMemo, useState } from "react";
import { FiEdit, FiEye } from "react-icons/fi";
import { IoScanSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import { setUser, setUserDetails } from "store/users";

export default function UsersTable() {
  const [qrUser, setQrUser] = useState(null);
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState(false);

  const { data: users, isLoading, error } = useFetchData(ApiConstants.USERS);

  const columns = useMemo(
    () => [
      {
        Header: "Profil",
        accessor: null,
        Cell: ProfileImg,
        canGroupBy: false,
      },
      {
        Header: "Kullanıcı Adı",
        accessor: "username",

        canGroupBy: false,
      },
      {
        Header: "Ad",
        accessor: "name",

        canGroupBy: false,
      },
      {
        Header: "Soyad",
        accessor: "surname",

        canGroupBy: false,
      },
      {
        Header: "Telefon",
        accessor: "phoneNumber",

        canGroupBy: false,
      },
      {
        Header: "Rol",
        accessor: "role",

        canGroupBy: false,
      },
      {
        Header: " ",
        accessor: null,
        Cell: EditBtn,

        canGroupBy: false,
      },
    ],
    [users]
  );

  // download QR code
  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL);
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = qrUser.name + " " + qrUser.surname + ".png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  return users == null ? (
    <Loading />
  ) : (
    <>
      {qrUser && (
        <CustomDialog
          isOpen={dialog}
          setIsOpen={setDialog}
          title={`${qrUser.name} ${qrUser.surname}`}
          body={
            <div className="flex  flex-col  items-center justify-center p-2">
              <QRCode id="qrCodeEl" size={200} value={qrUser._id} />
              <CustomButton
                className=" bg-green-50 mt-8  text-green-700 hover:bg-green-100 py-2 "
                onClick={downloadQRCode}
              >
                İndir
              </CustomButton>
            </div>
          }
        ></CustomDialog>
      )}
      <Table className="h-full" columns={columns} data={users} />
    </>
  );

  function EditBtn({ value, column, row }) {
    return (
      <div className="flex gap-x-3">
        <button
          onClick={() => {
            setQrUser(row.original);
            setDialog(true);
          }}
          className="bg-orange-100 text-orange-500 p-1.5 rounded-md  w-min"
        >
          <IoScanSharp />
        </button>
        <button
          onClick={() => dispatch(setUser(row.original))}
          className="bg-violet-100 text-violet-500 p-1.5 rounded-md  w-min"
        >
          <FiEdit />
        </button>
        <button
          onClick={() => {
            dispatch(setUser(row.original));
            dispatch(setUserDetails(true));
          }}
          className="bg-red-100 text-red-500 p-1.5 rounded-md  w-min"
        >
          <FiEye />
        </button>
      </div>
    );
  }
  function ProfileImg({ value, column, row }) {
    return (
      <div className="flex  items-center  w-12 h-12   ">
        <PreviewImage
          className=" rounded-full"
          file={null}
          src={row.original.img}
        />
      </div>
    );
  }
}
