import { useAsyncDebounce } from "react-table";
import React from "react";
import classNames from "classnames";
import { MdOutlineCancel } from "react-icons/md";

export default function ColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div className=" relative flex  ">
      <input
        className={classNames(
          "p-2 rounded-md bg-white  outline-none  focus:bg-blue-50",
          {
            "bg-emerald-200": filterValue,
          }
        )}
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`${count} kayıt...`}
      />
      {filterValue && (
        <div
          className="absolute top-1/2 cursor-pointer -right-5 transform -translate-y-1/2 rounded-full bg-slate-100 text-slate-700"
          onClick={() => setFilter(undefined)}
        >
          <MdOutlineCancel className="w-4 h-4" />
        </div>
      )}
    </div>
  );
}
