import Loading from "components/Loading";
import Table from "components/Table";
import { useMemo } from "react";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import { setCompany } from "store/company";

export default function CompanyTable() { 
  const dispatch = useDispatch(); 
  const {
    data: companies,
    isLoading,
    error,
  } = useFetchData(ApiConstants.COMPANIES);

  const columns = useMemo(
    () => [
      {
        Header: "Müşteri Adı",
        accessor: "companyName",
        canGroupBy: false,
      },
      {
        Header: " ",
        accessor: null,
        Cell: EditBtn,
        canGroupBy: false,
      },
    ],
    [companies]
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  return companies == null ? (
    <Loading />
  ) : (
    <Table
      className="h-full"
      columns={columns}
      data={companies}
      pageSize={13}
    />
  );
  function EditBtn({ value, column, row }) {
    return (
      <div className="  flex justify-end">
        <button
          onClick={() => dispatch(setCompany(row.original))}
          className="bg-red-100 text-red-500 p-1.5 rounded-md  justify-end flex "
        >
          <FiEdit />
        </button>
      </div>
    );
  }
}
