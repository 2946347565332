import * as Yup from "yup";
import "yup-phone";

export const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Minimum 2 karakter olmalıdır")
    .required("Lütfen doldurunuz"),
  surname: Yup.string()
    .min(2, "Minimum 2 karakter olmalıdır")
    .required("Lütfen doldurunuz"),
  username: Yup.string()
    .min(2, "Minimum 2 karakter olmalıdır")
    .required("Lütfen doldurunuz"),
  password: Yup.string()
    .min(6, `Minimum 6 karakter olmalıdır`)
    .required("Lütfen doldurunuz"),
});
