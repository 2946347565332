import { configureStore } from "@reduxjs/toolkit";
import usersSlice from "./users";
import authSlice from "./auth";
import companySlice from "./company";
import divisionSlice from "./division";
import projectSlice from "./project";
import panelSlice from "./panel";
import workSlice from "./works";
import dashboardSlice from "./dashboard";

const store = configureStore({
  reducer: {
    usersSlice,
    authSlice,
    companySlice,
    divisionSlice,
    projectSlice,
    panelSlice,
    workSlice,
    dashboardSlice
  },
});

export default store;
