import CustomDialog from "components/dialog";
import CustomButton from "components/Form/Button";
import Input from "components/Form/Input";
import { CustomListBox } from "components/Form/listbox";
import { Form, Formik } from "formik";
import { classNames } from "primereact/utils";
import { useState } from "react";
import { IoIosWarning } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import ProjectService from "services/project_service";
import { setProject } from "store/project";
import { mutate } from "swr";
import { showError, showSucces } from "utils/error";

export default function EditProject() {
  const project = useSelector((state) => state.projectSlice.project);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const dispatch = useDispatch();

  const {
    data: companies,
    isLoading,
    error,
  } = useFetchData(ApiConstants.COMPANIES);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;
  return (
    <>
      <CustomDialog
        isOpen={dialog}
        setIsOpen={setDialog}
        title={"Uyarı"}
        body={
          <div className="flex  flex-col  items-end">
            <div>
              <div className="flex justify-center items-center gap-x-2">
                <IoIosWarning className="h-14 w-14 text-red-500"></IoIosWarning>
                Projeyi silmek üzeresiniz. Bu işlem geri alınamaz. Devam etmek
                istediğinize emin misiniz?
              </div>
            </div>
            <div className="flex gap-x-4">
              <CustomButton
                className="text-slate-400 font-thin"
                onClick={() => setDialog(false)}
              >
                Vazgeç
              </CustomButton>
              <CustomButton
                className="bg-red-100 text-red-800 font-bold py-2"
                onClick={async () => {
                  try {
                    setDialog(false);
                    await ProjectService.delete(project._id);
                    dispatch(setProject(null));
                    mutate(ApiConstants.PROJECTS);
                    showSucces("Proje silindi");
                  } catch (error) {
                    setLoading(false);
                    showError(error);
                  }
                }}
              >
                Devam et
              </CustomButton>
            </div>
          </div>
        }
      ></CustomDialog>
      <div
        className={classNames(
          "shadow rounded-lg flex  flex-col items-center divide-y gap-4 transition-all bg-white p-4 "
        )}
      >
        <div className="w-full">
          <span className="text-red-400 font-bold text-lg self-start ">
            {project === null ? "Proje Ekle" : "Proje Güncelle"}
          </span>
          <Formik
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              projectName: project?.projectName || "",
              companyName: project?.companyName || "",
              lastCompanyName: project?.lastCompanyName || "",
            }}
            onSubmit={async (values, actions) => {
              setLoading(true);

              try {
                companies.map((company) => {
                  if (company.companyName == values.companyName)
                    values.companyID = company._id;
                });
                companies.map((company) => {
                  if (company.companyName == values.lastCompanyName)
                    values.lastCompanyID = company._id;
                });

                if (project) {
                  await ProjectService.update(project._id, values);
                  showSucces("Proje güncellendi");
                } else {
                  await ProjectService.add(values);
                  actions.resetForm();
                  showSucces("Proje kaydedildi");
                }

                mutate(ApiConstants.PROJECTS);
              } catch (error) {
                console.log(error);
                setLoading(false);
                showError(error);
              }
              setLoading(false);
            }}
          >
            {({ values }) => (
              <Form className="gap-y-4 flex flex-col  w-full   pt-8 ">
                <Input
                  type="text"
                  label="Proje Adı"
                  name="projectName"
                  required
                />
                <CustomListBox
                  label={"Müşteri"}
                  name="companyName"
                  values={companies.map((company) => company.companyName)}
                />
                <CustomListBox
                  label={"Son Müşteri"}
                  name="lastCompanyName"
                  values={companies.map((company) => company.companyName)}
                />
                <div className="flex w-full justify-evenly pt-3 self-end">
                  {project && (
                    <CustomButton
                      onClick={() => setDialog(true)}
                      type="reset"
                      className=" hover:bg-red-100 text-red-500 py-2 "
                    >
                      Sil
                    </CustomButton>
                  )}
                  <CustomButton
                    onClick={() => dispatch(setProject(null))}
                    type="reset"
                    className=" hover:bg-gray-100 text-gray-400 py-2"
                  >
                    Temizle
                  </CustomButton>

                  <CustomButton
                    type="submit"
                    className=" bg-red-50 text-red-700 hover:bg-red-100 py-2 "
                    loading={loading ? true : false}
                  >
                    {project ? "Güncelle" : "Ekle"}
                  </CustomButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
