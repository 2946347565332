import React, { useState } from "react";
import ApiConstants from "services/api"; 

const PreviewImage = ({
  file,
  src,
  err = ApiConstants.PF + "/default-profile.png",
  className,
}) => {
  const [preview, setpreview] = useState(null);

  if (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setpreview(reader.result);
    };
  }
  return ( 
      <img src={file ? preview || src || err : src || err} className={" w-full object-cover h-full " + className} />
  
  );
};



export default PreviewImage;
