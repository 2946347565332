import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isUserDetails : false,
  loading:false,
};

const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserDetails: (state, action) => {
      state.isUserDetails = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setUser,setUserDetails,setLoading } = usersSlice.actions;
export default usersSlice.reducer;
