import { ImRadioChecked2 } from "react-icons/im";
import { IoIosRadioButtonOff } from "react-icons/io";

export default function TableHead(headerGroups, enableFilters) {
  console.log(headerGroups);
  return (
    <thead className="">
      {headerGroups.map((headerGroup) => (
        <>
          <tr className="bg-slate-200" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              // Add the sorting props to control sorting. For this example
              // we can add them into the header props
              <th
                scope="col"
                className="px-2 pl-3 py-2.5 text-left text-xs font-medium  text-slate-800 uppercase tracking-wider "
                {...column.getHeaderProps()}
              >
                <div className="flex gap-x-2">
                  {column.canGroupBy ? (
                    // If the column can be grouped, let's add a toggle
                    <span {...column.getGroupByToggleProps()}>
                      {column.isGrouped ? (
                        <ImRadioChecked2 className="h-4 w-4 text-red-500" />
                      ) : (
                        <IoIosRadioButtonOff className="h-4 w-4" />
                      )}
                    </span>
                  ) : null}
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                  </span>
                </div>
              </th>
            ))}
          </tr>
          {enableFilters && (
            <tr className="bg-slate-50" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  scope="col"
                  className="px-2 pl-3 py-2.5 text-left text-xs font-medium  text-slate-800 uppercase tracking-wider "
                  {...column.getHeaderProps()}
                >
                  {/* Render the columns filter UI */}
                  <tr>{column.canFilter ? column.render("Filter") : null}</tr>
                </th>
              ))}
            </tr>
          )}
        </>
      ))}
    </thead>
  );
}
