import notify from "devextreme/ui/notify";
import React from "react";
import { formatDate } from "devextreme/localization"; 

export const  workTypeList = [ 
  { id: 0, text: "Mola", color: "#56ca85" },
  { id: 1, text: "Normal Mesai", color: "#1e90ff" },
  { id: 2, text: "Ek Mesai", color: "#ef4444" },
];

export function Appointment(model) {
  const { targetedAppointmentData } = model.data;
  console.log(targetedAppointmentData);
  return (
    <div className="showtime-preview ">
      <strong> {targetedAppointmentData.text}</strong>
      <div> {workTypeList[targetedAppointmentData.type].text}</div>
      <div> x{targetedAppointmentData.coefficient}</div>
      <div>
        {formatDate(targetedAppointmentData.displayStartDate, "shortTime")}  
        {" ••• "}
        {formatDate(targetedAppointmentData.displayEndDate, "shortTime")}
      </div>
      <div> {targetedAppointmentData.isContinued ? "Otomatik işe al" : "-"}</div>
    </div>
  );
}

export const notifyDisableDate = () => {
  notify("Bu saatler arasında zaten bir kayıt var.", "warning", 1000);
};

export const onAppointmentUpdating = (e) => {
  console.log(e.component.getDataSource().items);
  const startDate = new Date(e.newData.startDate);
  const endDate = new Date(e.newData.endDate);
  var items = e.component.getDataSource().items();
  for (var i = 0; i < items.length; i++) {
    var appointment = items[i];
    appointment.startDate =new Date(appointment.startDate).setSeconds(0,0);
    appointment.endDate =new Date(appointment.endDate).setSeconds(0,0);
    if (
      appointment.startDate != e.oldData.startDate &&
      appointment.endDate != e.oldData.endDate &&
      ((appointment.startDate >= startDate && appointment.endDate <= endDate) ||
        (appointment.startDate <= startDate &&
          appointment.endDate >= endDate) ||
        (appointment.endDate >= startDate && appointment.endDate <= endDate) ||
        (appointment.startDate >= startDate && appointment.startDate <= endDate))
    ) {
      notifyDisableDate();
      e.cancel = true;
    }
  }
};

export const onAppointmentAdding = (e) => {
  const startDate = new Date(e.appointmentData.startDate);
  const endDate = new Date(e.appointmentData.endDate);
  var items = e.component.getDataSource().items();
  for (var i = 0; i < items.length; i++) {
    var appointment = items[i];
    appointment.startDate =new Date(appointment.startDate).setSeconds(0,0);
    appointment.endDate =new Date(appointment.endDate).setSeconds(0,0);
    if (
      (appointment.startDate >= startDate && appointment.endDate <= endDate) ||
      (appointment.startDate <= startDate && appointment.endDate >= endDate) ||
      (appointment.endDate >= startDate && appointment.endDate <= endDate) ||
      (appointment.startDate >= startDate && appointment.startDate <= endDate)
    ) {
      notifyDisableDate();
      e.cancel = true;
    }
    appointment = { ...appointment, color: "#808080" };
    e.appointmentData = appointment;
  }
  console.log((e.appointmentData = appointment));
};
export const onAppointmentFormOpening = (e) => {
  const { form } = e;
  const requiredField = [{ type: "required", message: "Zorunlu alan" }];

  form.option("items", [
    {
      label: { text: "Başlık" },
      editorType: "dxTextBox",
      dataField: "text",
      editorOptions: {
        width: "100%",
      },
      validationRules: requiredField,
    },
    {
      label: { text: "Tip" },
      editorType: "dxSelectBox",
      dataField: "type",
      editorOptions: {
        items: workTypeList,
        displayExpr: "text",
        valueExpr: "id",
        width: "100%",
      },
      validationRules: requiredField,
    },
    {
      label: { text: "Başlangıç" },
      editorType: "dxDateBox",
      dataField: "startDate",
      editorOptions: {
        type: "time",
        interval: 15,
        width: "100%",
      },
      validationRules: requiredField,
    },
    {
      label: { text: "Bitiş" },
      editorType: "dxDateBox",
      dataField: "endDate",
      editorOptions: {
        type: "time",
        interval: 15,
        width: "100%",
      },
      validationRules: requiredField,
    },
    {
      label: { text: "Mesai Katsayısı" },
      editorType: "dxNumberBox",
      dataField: "coefficient",
      editorOptions: {
        value: 1,
        width: "100%",
      },
      validationRules: requiredField,
    },
    {
      label: { text: "İşe devam et" },
      editorType: "dxSwitch",
      dataField: "isContinued",  
    },
  ]);
};
