import { useState } from "react";

import CustomButton from "components/Form/Button";
import CustomDialog from "components/dialog";
import { BiBell, BiGroup } from "react-icons/bi";
import { BsRecordCircle } from "react-icons/bs";
import { IoIosWarning } from "react-icons/io";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import SocketService from "services/socket";
import WorkService from "services/works_service";

export default function HomePersonelHeader() {
  const [dialog, setDialog] = useState(false);
  const [bellDialog, setBellDialog] = useState(false);

  const { data: users, isLoading, error } = useFetchData(ApiConstants.USERS);
  const {
    data: works,
    workIsLoading,
    workerror,
  } = useFetchData(ApiConstants.ACTIVE_WORKS);

  if (isLoading || workIsLoading) return <div>Loading...</div>;
  if (error || workerror) return <div>Error...</div>;

  return (
    <>
      {buildEndWorkDialog(dialog, setDialog)}
      {buildBellDialog(bellDialog, setBellDialog)}
      <div className="flex   bg-white flex-wrap justify-between items-center py-2 px-3  gap-x-2 rounded-md">
        <div className="flex gap-x-5 ">
          <div className="p-2  flex    hover:bg-slate-50 transition-all border-slate-100 rounded-lg h-min items-center gap-x-2 ">
            <div className="h-10 w-10 rounded-full items-center flex justify-center bg-slate-100 p-2">
              <BiGroup className="h-6 w-6 text-slate-800"></BiGroup>
            </div>
            <div className="flex flex-col">
              <div className="text-sm text-slate-400 font-extralight">
                Toplam personel
              </div>
              <div className="text-base text-slate-800 font-semibold">
                {`${users.length} personel`}
              </div>
            </div>
          </div>
          <div className="p-2  flex    hover:bg-slate-50 transition-all border-slate-100 rounded-lg h-min items-center gap-x-2 ">
            <div className="h-10 w-10 rounded-full items-center flex justify-center bg-red-100 p-2">
              <BsRecordCircle className="h-6 w-6 text-red-500"></BsRecordCircle>
            </div>
            <div className="flex flex-col">
              <div className="text-sm text-slate-400 font-extralight">
                Anlık Çalışan
              </div>
              <div className="text-base text-slate-800 font-semibold">
                {`${
                  works != null
                    ? works.filter((v) => v.endedDate === null).length + " kişi"
                    : "..."
                }`}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-x-2   ">
          <CustomButton
            className="bg-red-100 text-red-800 font-bold py-2 h-min "
            onClick={() => setDialog(true)}
          >
            Bitir
          </CustomButton>
          <CustomButton
            className="bg-blue-100 text-blue-800 font-bold py-3 h-min "
            onClick={() => setBellDialog(true)}
          >
            <BiBell />
          </CustomButton>
        </div>
      </div>
    </>
  );
}
function buildBellDialog(bellDialog, setBellDialog) {
  return (
    <CustomDialog
      isOpen={bellDialog}
      setIsOpen={setBellDialog}
      title={"Uyarı"}
      body={
        <div className="flex  flex-col items-start  ">
          <div>
            <div className="flex justify-center items-center gap-x-2 self-star  ">
              <IoIosWarning className="h-14 w-14 text-red-500"></IoIosWarning>
              Zili çalmak üzeresiniz devam etmek istiyor musunuz?
            </div>
          </div>
          <div className="flex gap-x-4 justify-end w-full">
            <CustomButton
              className="text-slate-400 font-thin"
              onClick={() => setBellDialog(false)}
            >
              Vazgeç
            </CustomButton>
            <CustomButton
              className="bg-blue-100 text-blue-800 font-bold py-2"
              onClick={() => {
                setBellDialog(false);
                SocketService.emitBell();
              }}
            >
              Devam et
            </CustomButton>
          </div>
        </div>
      }
    ></CustomDialog>
  );
}

function buildEndWorkDialog(dialog, setDialog) {
  return (
    <CustomDialog
      isOpen={dialog}
      setIsOpen={setDialog}
      title={"Uyarı"}
      body={
        <div className="flex  flex-col  items-end">
          <div>
            <div className="flex justify-center items-center gap-x-2">
              <IoIosWarning className="h-14 w-14 text-red-500"></IoIosWarning>
              Çalışan bütün personellerin mevcut işleri sonlandırılacak. İşleme
              devam etmek istiyor musunuz?
            </div>
          </div>
          <div className="flex gap-x-4">
            <CustomButton
              className="text-slate-400 font-thin"
              onClick={() => setDialog(false)}
            >
              Vazgeç
            </CustomButton>
            <CustomButton
              className="bg-red-100 text-red-800 font-bold py-2"
              onClick={() => {
                WorkService.completeAllWork();
                SocketService.emitBell();
                setDialog(false);
              }}
            >
              Devam et
            </CustomButton>
          </div>
        </div>
      }
    ></CustomDialog>
  );
}
