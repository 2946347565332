import Loading from "components/Loading";
import Table from "components/Table";
import { useMemo } from "react";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import { setProject } from "store/project";

export default function ProjectTable() {
  const dispatch = useDispatch();

  const {
    data: projects,
    isLoading,
    error,
  } = useFetchData(ApiConstants.PROJECTS);

  const columns = useMemo(
    () => [
      {
        Header: "Proje Adı",
        accessor: "projectName",
        canGroupBy: false,
      },
      {
        Header: "Müşteri",
        accessor: "companyName",
        canGroupBy: false,
      },
      {
        Header: "Son Müşteri",
        accessor: "lastCompanyName",
        canGroupBy: false,
      },
      {
        Header: " ",
        accessor: null,
        Cell: EditBtn,
        canGroupBy: false,
      },
    ],
    [projects]
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  return projects == null ? (
    <Loading />
  ) : (
    <Table className="h-full" columns={columns} data={projects} pageSize={13} />
  );
  function EditBtn({ value, column, row }) {
    return (
      <div className="  flex justify-end">
        <button
          onClick={() => dispatch(setProject(row.original))}
          className="bg-red-100 text-red-500 p-1.5 rounded-md  justify-end flex "
        >
          <FiEdit />
        </button>
      </div>
    );
  }
}
