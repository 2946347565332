import { Grid } from "@mui/material";
import "react-circular-progressbar/dist/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import WorkingHours from "../dashboard/workingHours";
import "./css/carousel.css";
import DashboardNotWorkUsers from "./notWorkingUsers";
import DashboardPanels from "./panels";
import DashboardWorkingUsers from "./workingUsers";

function DashboardScreen() {
  return (
    <Grid
      container
      height={"100vh"}
      sx={{
        "--Grid-borderWidth": "1px",
        borderTop: "var(--Grid-borderWidth) solid",
        borderLeft: "var(--Grid-borderWidth) solid",
        borderColor: "divider",
        "& > div": {
          borderRight: "var(--Grid-borderWidth) solid",
          borderColor: "divider",
        },
      }}
    >
      <Grid item container height={"65vh"}>
        <Grid item xs={12} md={6}>
          <DashboardNotWorkUsers />
        </Grid>
        <Grid item xs={12} md={6} className="flex justify-end">
          <DashboardPanels />
          <WorkingHours />
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        container
        height={"35vh"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          "--Grid-borderWidth": "1px",
          borderTop: "var(--Grid-borderWidth) solid",
        }}
      >
        <Grid item xs={12}>
          <DashboardWorkingUsers />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DashboardScreen;
