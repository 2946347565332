import Loading from "components/Loading";
import { CustomProgressBar } from "components/ProgressBar";
import Table from "components/Table";
import { OverflowCell } from "components/Table/components/overflow";
import CustomToolTip from "components/tooltip";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import { setPanels } from "store/panel";

export default function HomeLastProject() {
  const panels = useSelector((state) => state.panelSlice.panels);
  const [data, setData] = useState(null);
  const dispatch = useDispatch(); 

  const {
    data: panelData,
    isLoading,
    error,
  } = useFetchData(ApiConstants.PANELS + "?withDetailsWithWorks=true&limit=5");

  useEffect(() => {
    if (panelData) {
      dispatch(setPanels(panelData));
      setData(panelData);
    }
  }, [panelData]);

  useEffect(() => {
    if (panels) {
      var _data = panels;
      _data = _data.map((v) => {
        const percentTime =
          v.totalTime === 0 ? 0 : ((v.usedTime / v.totalTime) * 100).toFixed(0);

        const percentBudget =
          v.totalBudget === 0
            ? 0
            : ((v.usedBudget / v.totalBudget) * 100).toFixed(0);
        return {
          ...v,
          percentTime,
          percentBudget,
        };
      });

      setData(_data);
    }
  }, [panels]);

  const columns = useMemo(() => {
    const col = [
      {
        Header: "Pano Adı",
        accessor: "panelName",
        Cell: OverflowCell,
        canGroupBy: false,
      },
      {
        Header: "Pano Kodu",
        accessor: "panelCode",
        Cell: OverflowCell,
        canGroupBy: false,
      },
      {
        Header: "Bütçe",
        accessor: "percentBudget",
        Cell: BudgetColumn,
        canGroupBy: false,
      },
      {
        Header: "Saat",
        accessor: "percentTime",
        filter: "between",
        canGroupBy: false,
        Cell: TimeColumn,
      },
    ];
    return col;
  }, [panels]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  return (
    <div className="  w-2/3 rounded-md h-96 flex flex-col bg-white p-2 gap-y-2">
      <div className=" text-sm text-slate-400">Son çalışma yapılan panolar</div>
      <div className="overflow-auto ">
        {data == null ? <Loading /> : buildTable()}
      </div>
    </div>
  );
  function buildTable() {
    return (
      <>
        <Table
          className="h-full relative"
          columns={columns}
          data={data}
          pageSize={50}
          exportExcel={false}
          fileName="Panolar"
        />
      </>
    );
  }

  function BudgetColumn({ value, column, row }) {
    return (
      <>
        <CustomToolTip
          content={` ${row.original.usedBudget} / ${row.original.totalBudget} ₺`}
        >
          <CustomProgressBar value={value} color="#34d399" />
        </CustomToolTip>
      </>
    );
  }
  function TimeColumn({ value, column, row }) {
    return (
      <>
        <CustomToolTip
          content={` ${row.original.usedTime} / ${row.original.totalTime} sa`}
        >
          <CustomProgressBar value={value} color="#60a5fa" />
        </CustomToolTip>
      </>
    );
  }
}
