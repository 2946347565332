import React from "react";
import imgMan from "asset/images/man.png";
import { useSelector } from "react-redux";
import PreviewImage from "components/PreviewImage";

export default function Header() {
  const user = useSelector((state) => state.authSlice.user);
  return (
    <header className=" flex px-6 bg-transparent h-16 justify-end items-center">
      <span className="font-semibold pr-2">
        {user.name} {user.surname}
        
      </span>

      <PreviewImage
        className=" rounded-full h-12 w-12 p-1"
        file={null}
        src={user.img}
      />
      {/* <img src={imgMan} alt="Profile" className="object-cover h-12"></img> */}
    </header>
  );
}
