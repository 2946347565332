import { Grid } from "@mui/material";
import HomeWorkHours from "pages/home/workHours";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import PanelService from "services/panel_service";
import { setPanels } from "store/dashboard";
const userRoles = ["Calisan", "Usta", "Stajyer"];

function DashboardNotWorkUsers() {
  const [notWorkingUsers, setNotWorkingUsers] = useState(null);
  const dispatch = useDispatch();

  const { data: users } = useFetchData(ApiConstants.USERS);

  const { data: workingUsers } = useFetchData(ApiConstants.ACTIVE_WORKS);

  useEffect(() => {
    if (users && workingUsers) {
      const willWorkUser = users.filter((v) => {
        return userRoles.includes(v.role) && v.state !== "Izinli";
      });
      const res = willWorkUser.filter((v) => {
        return !workingUsers.find((w) => w.userID === v._id);
      });

      const panelIDs = new Set(workingUsers.map((v) => v.panelID));
      const query = `?panelIDs=${[...panelIDs].join(
        ","
      )}&withDetailsWithWorks=true`;
      PanelService.getAll({ query }).then((res) => {
        dispatch(setPanels(res));
      });
      setNotWorkingUsers(res);
    }
  }, [users, workingUsers]);

  const offDutyUsers = users?.filter((v) => {
    return v.state === "Izinli" && userRoles.includes(v.role);
  });
  return (
    <Grid
      container
      height={"65vh"}
      sx={{
        "--Grid-borderWidth": "1px",
        borderTop: "var(--Grid-borderWidth) solid",
        borderLeft: "var(--Grid-borderWidth) solid",
        borderColor: "divider",
        "& > div": {
          borderRight: "var(--Grid-borderWidth) solid",
          borderColor: "divider",
        },
      }}
    >
      <Grid item xs={2}>
        <div className="text-red-600 font-bold text-medium">
          İzinli Personeller - {offDutyUsers?.length}
        </div>
        <div className="h-[60vh]  overflow-auto">
          {offDutyUsers?.map((v) => {
            return (
              <div
                key={v._id}
                className="flex flex-col  justify-center items-center  "
              >
                <div className="w-20">
                  {v.img ? (
                    <div className="bg-red-50 text-red-700 text-3xl p-6 h-20 w-20  flex items-center relative justify-center rounded">
                      {(() => {
                        const firstInitial = v.name.charAt(0).toUpperCase();
                        const lastInitial = v.surname.charAt(0).toUpperCase();

                        return `${firstInitial}${lastInitial}`;
                      })()}
                    </div>
                  ) : (
                    <div className="p-6 h-20 w-20 flex items-center relative border-2 border-solid justify-center bg-red-50 text-red-700 text-1xl rounded">
                      <div>
                        {v.name.charAt(0)}
                        {v.surname.charAt(0)}
                      </div>
                    </div>
                  )}
                  <div className="  text-gray-700 text-xs py-2  flex w-full justify-center">
                    {v.name + " " + v.surname}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Grid>
      <Grid item xs={10} justifyContent={"center"} alignItems={"center"}>
        {notWorkingUsers !== null && notWorkingUsers.length !== 0 ? (
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={true}
            interval={4000}
            statusFormatter={(current, total) => (
              <div className="text-white  text-lg ">
                {current + " / " + total}
              </div>
            )}
            width={"100%"}
            thumbWidth={100}
            centerSlidePercentage={50}
            renderThumbs={() =>
              notWorkingUsers?.map((v) => {
                return (
                  <div
                    key={v._id}
                    className="flex flex-col justify-center items-center  w-full"
                  >
                    {v.img ? (
                      <img src={v?.img ?? ""} alt={v.name} />
                    ) : (
                      <div className=" h-20 w-20 flex items-center relative  justify-center bg-red-50 text-red-700 text-2xl font-semibold rounded">
                        <div>
                          {v.name.charAt(0)}
                          {v.surname.charAt(0)}
                        </div>
                      </div>
                    )}

                    <div className="bg-red-500 text-white rounded-sm  text-xs py-1  flex w-full justify-center">
                      {v.name + " " + v.surname}
                    </div>
                  </div>
                );
              })
            }
          >
            {notWorkingUsers?.map((v) => {
              return (
                <div
                  key={v._id}
                  className="flex flex-col justify-center items-center  w-full"
                >
                  <div className="bg-red-500 text-white font-bold text-2xl py-2  flex w-full justify-center">
                    {v.name + " " + v.surname}
                  </div>
                  <div className="h-6" />
                  {v.img ? (
                    <img src={v?.img ?? ""} alt={v.name} />
                  ) : (
                    <div className=" h-80 w-80 flex items-center relative  justify-center bg-red-50 text-red-700 text-7xl font-medium rounded">
                      <div>
                        {v.name.charAt(0)}
                        {v.surname.charAt(0)}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </Carousel>
        ) : (
          <div className=" h-[60vh]    overflow-auto  ">
            <HomeWorkHours />
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default DashboardNotWorkUsers;
