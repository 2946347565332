import classNames from "classnames";
import React, { useEffect } from "react";
import { Button } from "primereact/button";

export default function RotateAnimation({ isFront, setIsFront, Front, Back }) {
  return (
    <div className="w-full bg-transparent transition-all  perspective rounded-lg group">
      <div
        className={classNames(
          "relative preserve-3d transition-all  w-full   duration-1000  rounded-lg",
          {
            "my-rotate-y-180": isFront,
          }
        )}
      >
        <div className="absolute  w-full   my-rotate-y-180 backface-hidden  rounded-lg">
          <Header />
          {Back}
        </div>
        <div className="absolute w-full   backface-hidden  rounded-lg z-10">
          {Front}
        </div>
      </div>
    </div>
  );
  function Header() {
    return (
      <div className="flex gap-x-4 items-center mb-3">
        <Button
          onClick={setIsFront}
          icon="pi pi-angle-left"
          iconPos="right"
          className="p-button p-button-secondary p-component p-button-rounded  p-button-outlined p-button-icon-only"
        />
        <span className=" text-lg font-semibold">Detaylar</span>
      </div>
    );
  }
}
