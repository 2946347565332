import React from "react";  
import EditCompany from "./components/edit_company";
import CompanyTable from "./components/table";

export default function CompainesPage() {
  return (
    <div className="flex   px-4 gap-x-6    w-full  relative">
      <div className=" w-full  rounded-md">
        <CompanyTable/>
      </div>
      <div className="w-1/3">
        <EditCompany/>
        
      </div>
    </div>
  );
}
