import Tooltip, { TooltipPrimitive } from "@atlaskit/tooltip";
import React from "react";
import { useState } from "react"; 
import styled from "@emotion/styled"; 

export default function CustomToolTip({ content, children }) {
  const [message, setMessage] = useState(false);
  const InlineDialog = styled(TooltipPrimitive)`
    opacity: 95%;
    background: #000000;
    color: white;
    border-radius: 4px;
    box-sizing: content-box;
    padding: 3px 8px;
    font-size: 0.8em;
  `;
  return (
    <> 
      <Tooltip
        content={message ? content : null}
        position={"top"}
        component={InlineDialog}
        
      >
        {(tooltipProps) => { 
          return (
            <div
              {...tooltipProps}
              onMouseOut={() => setMessage(false)}
              onClick={() => setMessage(!message)}
            >
              {children}
            </div>
          );
        }}
      </Tooltip>
    </>
  );
}
 