import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import CustomLottie from "components/Lottie";
import { workTypeList } from "pages/working_hours/components/scheduler_functions";
import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import WorkingHoursService from "services/working_hours_service";
import { AppUtils } from "utils/utils";
import noData from "./../../asset/lottie/no-work";

function WorkingHours() {
  const [selectedDay, setSelectedDay] = useState(
    AppUtils.dayOfWeek(new Date()) - 1
  );
  const [workingHours, setWorkingHours] = useState(null);
  useEffect(() => {
    const getAllWorkingHours = async () => {
      const data = await WorkingHoursService.getAll();
      setWorkingHours(
        data.sort(function (a, b) {
          return new Date(a.startDate) - new Date(b.startDate);
        })
      );
    };
    getAllWorkingHours();
  }, []);
  const changeDay = (isBack) => {
    if (isBack) {
      if (selectedDay === 0) return;
      setSelectedDay(selectedDay - 1);
    } else {
      if (selectedDay === 6) return;
      setSelectedDay(selectedDay + 1);
    }
  };
  const dotColor = ["success", "info", "error"];

  return (
    <div>
      {/* Çalışma saatleri */}
      <div className="flex bg-white mt-4 p-3 rounded-md font-medium text-slate-500">
        <div className="flex flex-col">
          <div className="flex justify-between select-none pb-4">
            <div className=" text-sm text-slate-400">Çalışma saatleri</div>
            <div className=" flex font-normal text-slate-400 items-center">
              <div className="font-medium text-sm text-red-500 px-2">
                {AppUtils.days[selectedDay]}
              </div>
              <IoIosArrowBack
                className="hover:bg-slate-100 rounded-full hover:cursor-pointer "
                onClick={() => changeDay(true)}
              />
              <div className="w-2" />
              <IoIosArrowForward
                className="hover:bg-slate-100 rounded-full hover:cursor-pointer "
                onClick={() => changeDay(false)}
              />
            </div>
          </div>
          <div className="flex w-full overflow-auto ">
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.2,
                },
              }}
            >
              {workingHours &&
              workingHours.filter(
                (e) => selectedDay + 1 === AppUtils.dayOfWeek(e.startDate)
              ).length === 0 ? (
                <div className="flex flex-col w-full items-center justify-center pt-10 ">
                  <CustomLottie animationData={noData} size={200} />
                  <div className="text-lg font-thin text-slate-400">
                    Bugün çalışma yok
                  </div>
                </div>
              ) : (
                workingHours &&
                workingHours
                  .filter(
                    (e) => selectedDay + 1 === AppUtils.dayOfWeek(e.startDate)
                  )
                  .map((v, index) => (
                    <TimelineItem key={index}>
                      <TimelineOppositeContent color="text.secondary">
                        <div className="text-sm text-slate-400 flex-col">
                          <div>{AppUtils.formatClock(v.startDate)}</div>
                          {index ===
                            workingHours.filter(
                              (e) =>
                                selectedDay + 1 ===
                                AppUtils.dayOfWeek(e.startDate)
                            ).length -
                              1 && (
                            <div className="pt-[35px]">
                              {AppUtils.formatClock(v.endDate)}
                            </div>
                          )}
                        </div>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot
                          variant={v.isContinued ? "filled" : "outlined"}
                          color={dotColor[v.type]}
                        />
                        <TimelineConnector sx={{ minHeight: "10px" }} />
                      </TimelineSeparator>
                      <TimelineContent>
                        <div className="flex gap-x-3 flex-col gap-y-2 ">
                          <div className="flex ">
                            <div className="flex text-xs p-1 border rounded-md">
                              {v.text}
                            </div>
                          </div>
                          <div className="flex">
                            <div
                              className="flex text-white text-xs p-1 border rounded-md"
                              style={{
                                backgroundColor: workTypeList[v.type].color,
                                opacity: 0.7,
                              }}
                            >
                              x{v.coefficient}
                            </div>
                          </div>
                        </div>
                      </TimelineContent>
                    </TimelineItem>
                  ))
              )}
            </Timeline>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkingHours;
