import Loading from "components/Loading";
import Table from "components/Table";
import { DateColumn } from "components/Table/components/date";
import { OverflowCell } from "components/Table/components/overflow";
import { useMemo } from "react";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";

export default function ActiveWorkReportsTable() {
 
  const {
    data: works,
    isLoading,
    error,
  } = useFetchData(ApiConstants.ACTIVE_WORKS);

  const columns = useMemo(
    () => [
      {
        Header: "Personel",
        accessor: "user",
      },
      {
        Header: "Bölüm",
        accessor: "divisionName",
      },
      {
        Header: "Pano",
        accessor: "panelName",
        Cell: OverflowCell,
      },
      {
        Header: "Proje",
        accessor: "projectName",
      },
      {
        Header: "Son Müşteri",
        accessor: "lastCompanyName",
        Cell: OverflowCell,
      },
      {
        Header: "Başlangıç",
        accessor: "startedDate",
        Cell: DateColumn,
        Filter: "",
        type: "date",
      },
      {
        Header: "",
        accessor: "endedDate",
        Cell: DateColumn,
        Filter: "",
        type: "date",
        export: false,
        isDateNull: "Çalışıyor",
        canGroupBy: false,
      },
    ],
    [works]
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  return works == null ? (
    <Loading />
  ) : (
    <Table
      className="h-full"
      columns={columns}
      data={works}
      pageSize={50}
      exportExcel
      fileName="Aktif_Çalışanlar"
    />
  );
}
