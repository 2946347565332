import Header from "components/Header";
import Sidebar from "components/Sidebar";
import { Outlet } from "react-router-dom";

export default function HomeLayout() {
  return (
    <>
      <div className="App h-full flex bg-slate-50">
        <Sidebar />
        <div className="flex flex-col w-full overflow-auto">
          <Header />
          <Outlet   />
        </div>
      </div>
    </>
  );
}
