import React, { useCallback, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useFilters,
  useGroupBy,
  useExpanded,
} from "react-table";

import TableContent from "./Content";
import TablePagination from "./Pagination";
import ColumnFilter from "./Filter/ColumnFilter";
import { fromDateFilterFn, toDateFilterFn } from "./Filter";
import ExcelButton from "./Export/ExcelButton";
import noData from "./../../asset/lottie/table-no-data";
import CustomLottie from "components/Lottie";

function Table({
  columns,
  data,
  className,
  pageSize = 10,
  exportExcel = false,
  fileName = "Table",
  Header,
  enableFilters = true,
  enablePageNumbers = true
}) {
  const filterTypes = React.useMemo(
    () => ({
      fromDate: fromDateFilterFn,
      toDate: toDateFilterFn,
    }),
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  );

  const {
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    rows,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetGroupBy: false,
      autoResetExpanded: false,
      disableMultiSort: false,
      initialState: { pageSize: pageSize },
    },
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
  );

  console.log("Header Groupppp:", headerGroups);

  return (
    <>
      {data.length === 0 ? (
        <div className="flex flex-col h-max justify-center  items-center text-slate-500 pt-20 ">
          <CustomLottie animationData={noData} />
          <div className="flex text-2xl  ">Kayıt bulunamadı...</div>
        </div>
      ) : (
        <div className="w-full overflow-x-auto flex">
        <div
          className={
            "shadow flex flex-col bg-white  h-min relative rounded-lg" +
            className
          }
        >
          <div className="flex items-center mt-2">
            {exportExcel && ExcelButton(fileName, columns, defaultColumn, rows)}
            {Header}
          </div>
          {TableContent(
            getTableProps,
            headerGroups,
            footerGroups,
            getTableBodyProps,
            page,
            prepareRow,
            enableFilters,
          )}
          {TablePagination(
            gotoPage,
            canPreviousPage,
            previousPage,
            nextPage,
            canNextPage,
            pageCount,
            state,
            pageOptions,
            setPageSize,
            enablePageNumbers
          )}
        </div>
        </div>
      )}
    </>
  );
}

/*
 <div className="flex items-center">
                    {cell.isGrouped ? (
                      // If it's a grouped cell, add an expander and row count
                      <>
                        <span {...row.getToggleRowExpandedProps()}>
                          {row.isExpanded ? (
                            <FiMinusSquare className="h-4 w-4 text-orange-500 mr-2" />
                          ) : (
                            <FiPlusSquare className="h-4 w-4 text-orange-500  mr-2" />
                          )}
                        </span>
                        {cell.render("Cell")} ({row.subRows.length})
                      </>
                    ) : cell.isAggregated ? (
                      // If the cell is aggregated, use the Aggregated
                      // renderer for cell
                      cell.render("Aggregated")
                    ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                      // Otherwise, just render the regular cell
                      cell.render("Cell")
                    )}
                  </div>
*/
export default Table;
