import classNames from "classnames";
import { Button } from "primereact/button";

export default function CustomButton({
  onClick,
  children,
  loading,
  className = "",
  label,
  ...probs
}) {
  return (
    <button
      onClick={onClick}
      children={children}
      label={label}
      className={classNames(
        " flex items-center gap-x-2 p-button-sm px-4 rounded-lg border-none font-medium " +
          className,{
            "disabled opacity-80":loading
          }
      )}
      {...probs}
    >
      <span
        className={classNames(
          "p-button-icon p-c p-button-loading-icon p-button-icon-left pi pi-spinner pi-spin ",
          { "hidden": !loading }
        )}
      ></span>
      {children}
    </button>
  );
}
