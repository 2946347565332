import WorkReportsTable from "pages/reports/tables/work_table";
import React, { useEffect, useState } from "react";
import WorkService from "services/works_service";

function ProcessMiningWorksDialog({ edgeData }) {
  const [works, setWorks] = useState(undefined);

  useEffect(() => {
    setWorks(undefined);
    const fetchData = async () => {
      const panelIDs = edgeData.works.map((data) => data._id);
      const query = `?panels=${panelIDs.join(",")}`;

      const works = await WorkService.getAll({ query });
      setWorks(works.data);
    };
    fetchData();
  }, [edgeData]);

  if (works === undefined) {
    return <>Loading... </>;
  }
  return <WorkReportsTable worksData={works ?? []}></WorkReportsTable>;
}

export default ProcessMiningWorksDialog;
