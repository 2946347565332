import io from "socket.io-client";

class SocketService {
  static espUrl = process.env.REACT_APP_ESP_SOCKET_URL;
  static espSocket = io.connect(this.espUrl);

  static bell = "bell";

  static onBell = (set) => {
    this.emitBell();
    this.espSocket.on(this.bell, (val) => set(val));
  };


  static emitBell = () => {
    this.espSocket.emit(this.bell);
  };
  
}
export default SocketService;
