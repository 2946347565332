import React from "react";
import VectorMap, {
  Layer,
  Tooltip,
  Border,
  Font,
} from "devextreme-react/vector-map";

import * as mapsData from "devextreme/dist/js/vectormap-data/world.js";

const bounds = [-180, 85, 180, -60];
const countries = {
  Turkey: { color: "#56ca85" },
  Mauritania: { color: "#1E90FF" },
  Algeria: { color: "#B8860B" },
  Germany: { color: "#BDB76B" },
  Libya: { color: "#FFA07A" },
  Nigeria: { color: "#3CB371" },
  Egypt: { color: "#FFD700" },
  Angola: { color: "#DB7093" },
  Iraq: { color: "#1E90FF" },
  Iran: { color: "#B8860B" },
  Bulgaria: { color: "#BDB76B" },
  Romania: { color: "#FFA07A" },
  Georgia: { color: "#3CB371" },
  Azerbaijan: { color: "#FFD700" },
  Uzbekistan: { color: "#DB7093" },
  Russia: { color: "#CD5C5C" },
  Kazakhstan: { color: "#FFD700" },
  Oman: { color: "#8A2BE2" },
  Qatar: { color: "#00BFFF" },
  Italy: { color: "#DC143C" },
};

export default function HomeMap() {
  return (
    <div className="  overflow-hidden  w-2/3 rounded-md">
      <VectorMap id="vector-map" bounds={bounds}>
        <Layer dataSource={mapsData.world} customize={customizeLayer}></Layer>
        <Tooltip enabled={true} customizeTooltip={customizeTooltip}>
          <Border visible={true}></Border>
          <Font color="#fff"></Font>
        </Tooltip>
      </VectorMap>
    </div>
  );
}

function customizeTooltip(arg) {
  const name = arg.attribute("name");
  const country = countries[name];
  if (country) {
    return {
      text: `${name}`,
      color: country.color,
    };
  }
  return null;
}
function customizeLayer(elements) {
  elements.forEach((element) => {
    const country = countries[element.attribute("name")];
    if (country) {
      element.applySettings({
        color: country.color,
        hoveredColor: "#e0e000",
        selectedColor: "#008f00",
      });
    }
  });
}
