 
import React from "react";
import { useSelector } from "react-redux";
import UsersTable from "./table";
import UsersDetail from "./user_detail";
import RotateAnimation from "components/RotateAnimation";

import { useDispatch } from "react-redux";
import { setUserDetails } from "store/users";
export default function UsersBody() {
  const isUserDetails = useSelector((state) => state.usersSlice.isUserDetails);
  const dispatch = useDispatch();
  return (
    <>
      <RotateAnimation
        isFront={isUserDetails ? true : false}
        setIsFront={() => dispatch(setUserDetails(false))}
        Front={<UsersTable />}
        Back={<UsersDetail />}
      />
    </>
  );
}
