import React from "react";
import EditProject from "./components/edit_project";
import ProjectTable from "./components/table";

export default function ProjectsPage() {
  return (
    <div className="flex   px-4 gap-x-6    w-full  relative">
      <div className=" w-full  rounded-md">
        <ProjectTable />
      </div>
      <div className="w-1/3">
        <EditProject />
      </div>
    </div>
  );
}
