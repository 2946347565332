import ApiConstants from "./api";
import { deleteData, fetchData, postData, putData } from "./axios_helper";
class PanelService {
  static async getAll({ query }) {
    const res = await fetchData(ApiConstants.PANELS + query);
    return res;
  }
  static async getPanel(id) {
    const res = await fetchData(ApiConstants.PANELS + "/" + id);
    return res;
  }

  static async add(data) {
    const res = await postData(ApiConstants.PANELS, data);
 
    return res;
  }

  static async update(id, data) {
    const res = await putData(ApiConstants.PANELS + "/" + id, data);
    return res;
  }
  static async delete(id) {
    const res = await deleteData(ApiConstants.PANELS + "/" + id);
    return res;
  }
}

export default PanelService;
