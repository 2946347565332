import ApiConstants from "./api";
import { fetchData, postData } from "./axios_helper";
class WorkingHoursService {
  static async set(data) {
    const res = await postData(ApiConstants.WORKING_HOURS, data);
    return res;
  }

  static async getAll() {
    const res = await fetchData(ApiConstants.WORKING_HOURS);
    return res;
  }
}

export default WorkingHoursService;
