
import woman from "asset/images/woman.jpg";
import PreviewImage from "components/PreviewImage";
import { FiUser ,FiPhone,FiCalendar,FiClock } from "react-icons/fi";
import {VscSymbolNamespace,VscSourceControl} from "react-icons/vsc"
import { useSelector } from "react-redux";
import { AppUtils } from "utils/utils";
export default function Profile() {
  const user = useSelector((state) => state.usersSlice.user);  
    return (
      <div className=" ">
        <div className="  px-24 w-full  pb-8 ">
        <PreviewImage
          className="rounded-full"  
          src={user?.img}
        />  
        </div>
      
        <DetailColumn icon={<FiUser/>} title={"Ad Soyad"} value={user?.name+" "+user?.surname} />
        <DetailColumn icon={<VscSymbolNamespace/>} title={"Kullanıcı Adı"} value={user?.username} />
        <DetailColumn icon={<FiPhone/>} title={"Telefon"} value={user?.phoneNumber} />
        <DetailColumn icon={<VscSourceControl/>} title={"Rol"} value={user?.role} />
        <DetailColumn icon={<FiCalendar/>} title={"İşe giriş"} value={AppUtils.formatDate(user?.startedDate)} />
        <DetailColumn icon={<FiClock/>} title={"Toplam çalışma (Saat)"} value={325} />
      </div>
    );
  }
  
  function DetailColumn({icon, title, value }) {
    return (
      <div className="flex items-center gap-x-4 pb-2">
        <div className="p-2 rounded-full bg-red-100 text-red-900">
        {icon}
        </div>
      <div className="flex flex-col  ">
        <p className="text-sm  text-slate-400  font-thin ">{title}</p>
        <p className="text-md font-thin text-red-900">{value}</p>
      </div>
      </div>
    );
  }
  