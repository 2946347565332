 
import React from "react";
import { useDispatch, useSelector } from "react-redux"; 
import PanelDetailReportHeader from "./header";
import PanelDetailReportTable from "./panel_detail_table";

export default function PanelDetailReport() {
  const selectedWorks = useSelector((state) => state.workSlice.selectedWorks);
  const dispatch = useDispatch();
  return ( 
    <>
      <PanelDetailReportHeader></PanelDetailReportHeader>
      <PanelDetailReportTable></PanelDetailReportTable>
    </>
  );
}

 