import { TabView, TabPanel } from 'primereact/tabview';
import React from "react";
import WorkReportsTable from './tables/work_table'; 
import PanelReportsTable from './tables/panel_table';
import ActiveWorkReportsTable from './tables/active_work_table';
import PanelTable from 'pages/panels/components/table'; 

export default function ReportsPage() {
  return (
    <div className="flex px-4 gap-x-6   w-full  relative">
      <div className=" w-full  rounded-md"> 
        <TabView
          activeIndex={0} 
          className='shadow-none'
        > 
          <TabPanel header="Aktif Çalışanlar"><ActiveWorkReportsTable/></TabPanel>
          <TabPanel header="İş Kayıtları"><WorkReportsTable/></TabPanel>
          {/* <TabPanel header="Personel Raporu"><WorkReportsTable/></TabPanel> */}
          <TabPanel header="Pano Raporu"> <PanelReportsTable/></TabPanel>
        </TabView>
      </div>
    </div>
  );
}
