import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import CustomButton from "components/Form/Button";
import { Form, Formik } from "formik";
import Input from "components/Form/Input";
import { UserSchema } from "utils/validations";
import { setUser } from "store/users";
import { classNames } from "primereact/utils";
import FileInput from "components/Form/FileInput";
import { DateInput } from "components/Form/DatePicker";
import Selection from "components/Form/Selection";
import AppConstants from "utils/constants/app_constants";
import CustomInputNumber from "components/Form/InputNumber";
import { showError, showSucces } from "utils/error";
import AuthServices from "services/auth_service";
import UsersService from "services/users_service";
import { MdOutlineVisibilityOff, MdOutlineVisibility } from "react-icons/md";
import { CustomListBox } from "components/Form/listbox";
import CustomDialog from "components/dialog";
import { IoIosWarning } from "react-icons/io";
import { mutate } from "swr";
import ApiConstants from "services/api";


export default function EditUser() {
  const user = useSelector((state) => state.usersSlice.user);
  const isUserDetails = useSelector((state) => state.usersSlice.isUserDetails);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [hourlyPriceVisible, setHourlyPriceVisible] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
    <CustomDialog
      isOpen={dialog}
      setIsOpen={setDialog}
      title={"Uyarı"}
      body={
        <div className="flex  flex-col  items-end">
          <div>
            <div className="flex justify-center items-center gap-x-2">
              <IoIosWarning className="h-14 w-14 text-red-500"></IoIosWarning>
              Kullanıcıyı silmek üzeresiniz. Bu işlem geri alınamaz. Devam etmek
              istediğinize emin misiniz?
            </div>
          </div>
          <div className="flex gap-x-4">
            <CustomButton
              className="text-slate-400 font-thin"
              onClick={() => setDialog(false)}
            >
              Vazgeç
            </CustomButton>
            <CustomButton
              className="bg-red-100 text-red-800 font-bold py-2"
              onClick={async () => {
                try {
                  setDialog(false);
                  await UsersService.delete(user._id);
                  dispatch(setUser(null));
                  mutate(ApiConstants.USERS);
                  showSucces("Kullanıcı silindi");
                } catch (error) {
                  setLoading(false);
                  showError(error);
                }
              }}
            >
              Devam et
            </CustomButton>
          </div>
        </div>
      }
    ></CustomDialog>
      <div
        className={classNames(
          "shadow  rounded-lg  flex flex-col items-center divide-y gap-4 transition-all bg-white",
          {
            "translate-x-full w-0 duration-1000 ": isUserDetails,
            "w-1/3 p-4 duration-100": !isUserDetails,
          }
        )}
      >
        <div
          className={classNames("duration-1000 transition-all ", {
            hidden: isUserDetails,
          })}
        >
          <span className="text-red-400 font-bold text-lg self-start ">
            {user == null ? "Personel Ekle" : "Personel Güncelle"}
          </span>
          <Formik
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              name: user?.name || "",
              surname: user?.surname || "",
              username: user?.username || "",
              phoneNumber: user?.phoneNumber || "",
              hourlyPrice: user?.hourlyPrice || 0,
              password: user ? "*1*1*1" : "",
              img: user ? user.img : null,
              startedDate: new Date(user?.startedDate),
              role: user?.role || "",
              state: user?.state === "Izinli" ? "Izinli" : "Şirkette",
            }}
            onSubmit={async (values, actions) => {
              const data = { ...values };
              console.log('----------------');
              setLoading(true);
              try {
                if (data.state === "Şirkette") {
                  if (user?.state === "Izinli") {
                    data.state = "Bosta";
                  } else {
                    delete data.state;
                  }
                }
                if (user) {
                  if (values.password === "*1*1*1") delete data.password;

                  if (data.img && typeof data.img === "object") {
                    const imgURL = await UsersService.uploadProfile(
                      user._id,
                      data.img
                    );
                    data.img = imgURL;
                  }
                  
                  await UsersService.update(user._id, data);
                  showSucces("Kullanıcı güncellendi");
                } else {

                  await AuthServices.register(data);
                  actions.resetForm();
                  showSucces("Kullanıcı kaydedildi");
                }

                mutate(ApiConstants.USERS)

              } catch (error) {
                console.log(error);
                setLoading(false);
                showError(error);
              }
              setLoading(false);
            }}
            validationSchema={UserSchema}
          >
            {({ values }) => (
              <Form className="gap-y-4 flex flex-col  w-full   pt-8 ">
                {user && <FileInput name="img" accept="image/*" />}

                <div className="pt-2 flex gap-x-2">
                  <Input type="text" label="Ad" name="name" required />
                  <Input type="text" label="Soyad" name="surname" required />
                </div>
                <Input
                  type="text" 
                  label="Kullanıcı adı"
                  name="username"
                  required
                />
                <Input
                  type="password"
                  label="Şifre"
                  minLength="6"
                  name="password"
                  required
                />
                <Input
                  type="text"
                  // pattern="[0-9]{11}"
                  label="Telefon"
                  name="phoneNumber"
                  title="11 haneli telefon numarası giriniz"
                />
                <DateInput label="İşe giriş tarihi" name="startedDate" />

                <CustomListBox
                  label="Rol"
                  name="role"
                  values={AppConstants.userRole}
                />
                {user?.state !== "Calisiyor" ? (
                  <CustomListBox
                    label="Durum"
                    name="state"
                    values={AppConstants.userState}
                  />
                ) : null}

                <CustomInputNumber
                  label="Saatlik Ücret"
                  name="hourlyPrice"
                  type={hourlyPriceVisible ? "text" : "password"}
                  suffix_icon={
                    <div
                      className="cursor-pointer hover:cursor-pointer relative"
                      onClick={() => setHourlyPriceVisible(!hourlyPriceVisible)}
                    >
                      {hourlyPriceVisible ? (
                        <MdOutlineVisibility className="w-4 h-4   "></MdOutlineVisibility>
                      ) : (
                        <MdOutlineVisibilityOff className="w-4 h-4   "></MdOutlineVisibilityOff>
                      )}
                    </div>
                  }
                  mode="decimal"
                  suffix={" ₺"}
                  required
                />
                <div className="flex w-full justify-evenly pt-3 self-end">
                  {" "}
                  {user && (
                    <CustomButton
                      onClick={() => setDialog(true)}
                      type="reset"
                      className=" hover:bg-red-100 text-red-500 py-2 "
                    >
                      Sil
                    </CustomButton>
                  )}
                  <CustomButton
                    onClick={() => dispatch(setUser(null))}
                    type="reset"
                    className=" hover:bg-gray-100 text-gray-400 py-2"
                  >
                    Temizle
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    className=" bg-red-50  text-red-700 hover:bg-red-100 py-2 "
                    loading={loading ? true : false}
                  >
                    {user ? "Güncelle" : "Ekle"}
                  </CustomButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
