 
import React from "react"; 
import Profile from "./user_detail_profile";
import UserDetailProjectGraph from "./user_detail_project_graph";
import UserDetailTimeChart from "./user_detail_time_chart"; 

export default function UsersDetail() {
  return (
    <div className="flex flex-col h-full  w-full rounded-lg   p-4   bg-white gap-y-4 overflow-hidden">
  
      <div className="flex w-full h-full ">
        <div className="flex flex-col w-1/3 h-full p-2 gap-y-1">
          <Profile />
        </div> 
        <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden ">
          <div className=" w-full flex  gap-x-6 justify-around px-2 ">
            <div className="   w-1/3  ">
              <UserDetailTimeChart />
            </div>
            <div className=" w-2/3">
              <UserDetailProjectGraph />
            </div>
          </div>
        </div>
      </div>
    </div>
  ); 
}
