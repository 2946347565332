import { AppUtils } from "utils/utils";
import moment from "moment";

export function DateColumn({ value, column, row }) { 
  return value
    ? moment(value).format(column.dateFormat || "DD-MM-YYYY HH:mm")
    : column.isDateNull && (
        <div className="px-4 py-1 w-min text-xs font-semibold rounded-lg bg-orange-100 text-orange-500 ">
          {column.isDateNull}
        </div>
      );
} 