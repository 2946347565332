import React, { useEffect, useState } from "react";
import Scheduler, { Resource } from "devextreme-react/scheduler";
import {
  Appointment,
  onAppointmentAdding,
  onAppointmentFormOpening,
  onAppointmentUpdating,
  workTypeList,
} from "./scheduler_functions.js";
import { locale } from "devextreme/localization";
import CustomButton from "components/Form/Button.js";
import WorkingHoursService from "services/working_hours_service.js";
import Loading from "components/Loading.js";
import { AppUtils } from "utils/utils.js";

export default function WorkingHoursWorklyTable() {
  const currentDate = new Date();
  const [workingHours, setWorkingHours] = useState(null);
  useEffect(() => {
    const getAllWorkingHours = async () => {
      const data = await WorkingHoursService.getAll();
      for (const key in data) {
        data[key].startDate = AppUtils.weekDay(
          AppUtils.dayOfWeek(data[key].startDate)
        ).setHours(
          new Date(data[key].startDate).getHours(),
          new Date(data[key].startDate).getMinutes()
        );
        data[key].endDate = AppUtils.weekDay(
          AppUtils.dayOfWeek(data[key].endDate)
        ).setHours(
          new Date(data[key].endDate).getHours(),
          new Date(data[key].endDate).getMinutes()
        );
      }
      setWorkingHours(data);
    };

    getAllWorkingHours();
  }, []);
  useEffect(() => {
    if (workingHours != null) {
      const toolbar = document.getElementsByClassName("dx-scheduler-header");
      toolbar[0].classList.add("dx-hide");
    }
  }, [workingHours]);
  locale("tr");
  return workingHours == null ? (
    <Loading />
  ) : (
    <div>
      <div className="flex w-min   mb-3 ">
        <CustomButton
          className="bg-green-200 py-2"
          onClick={() => WorkingHoursService.set(workingHours)}
        >
          Kaydet
        </CustomButton>
      </div>
      <Scheduler
        allDayPanelMode={"hidden"}
        dataSource={workingHours}
        views={["week"]}
        defaultCurrentView="week"
        defaultCurrentDate={currentDate}
        startDayHour={0}
        endDayHour={24}
        cellDuration={15}
        onAppointmentUpdating={onAppointmentUpdating}
        className="bg-red-100"
        useDropDownViewSwitcher={false}
        appointmentComponent={Appointment}
        onAppointmentFormOpening={onAppointmentFormOpening}
        onAppointmentAdding={onAppointmentAdding}
      >
        <Resource
          dataSource={workTypeList}
          fieldExpr="type"
          useColorAsDefault={true}
        />
      </Scheduler>
    </div>
  );
}
