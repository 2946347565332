import React from "react";
import EditPanel from "./components/edit_panel"; 
import PanelTable from "./components/table"; 

export default function PanlesPage() {
  return (
    <div className="flex   px-4 gap-x-6    w-full  relative">
      <div className=" w-full  rounded-md">
        <PanelTable />
      </div>
      <div className="w-1/4   flex-shrink-0  ">
        <EditPanel />
      </div>
    </div>
  );
}
