import React from "react";
import { useField } from "formik";  
import { Calendar } from 'primereact/calendar';
import classNames from "classnames";

export const DateInput = ({ label, ...probs }) => {
  const [field, meta, helpers] = useField(probs); 
  return (
    <label className="flex flex-col gap-y-1 relative ">
      <Calendar 
        dateFormat="dd-mm-yy"
        inputClassName={classNames(
          "border-none rounded-md bg-inputBackground   select-none pointer-events-none disabled  ",
          {
            "pt-[1.32rem]   ": field.value,
          }
        )}
        className={classNames(
          "bg-red-400 rounded-lg  select-none pointer-events-none   border-none"
        )}
        showIcon
        {...field}
        {...probs}
      />      <small
        className={classNames({
          "absolute left-[13px]    select-none pointer-events-none  text-gray-400 -translate-y-1/2 transition-all ": true,
          "text-sm top-1/2": !field.value,
          "text-[13px] top-3": field.value,
        })}
      >
        {label}
      </small>
    </label>
  );
};