import React, { useCallback } from "react";
import { exportToExcel, getColumns, getRows } from "./excelExport";
import { SiMicrosoftexcel } from "react-icons/si";

export default function ExcelButton(fileName, columns, defaultColumn, rows) {
  const doExportToExcel = useCallback(() => {
    const resultColumns = getColumns(columns, defaultColumn);
    const resultRows = getRows(rows, resultColumns);
    return exportToExcel(
      {
        sheet: {
          sheetName: fileName || "Excel Export",
          columns: resultColumns,
          rows: resultRows,
        },
      },
      `${fileName || "Excel_Export"}_${new Date().toLocaleDateString()}`
    );
  }, [fileName, columns, defaultColumn, rows]);

  return (
    <div className="flex  items-center justify-start px-6 ">
      <button onClick={doExportToExcel}>
        <div className="bg-green-200 text-sm text-green-700 py-1.5 px-3 rounded-md flex gap-x-2 items-center justify-center" >
          <SiMicrosoftexcel />
          Excel
        </div>
      </button>
    </div>
  );
}
