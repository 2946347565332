import React from "react"; 

import ReactLoading from 'react-loading';
export default function Loading() {
  return (
    <div className="w-full h-full items-center flex justify-center flex-col gap-y-1">
        
    <ReactLoading type='bubbles'      color="#ef4444" />
    <h1 className="text-base font-semibold text-red-500">Yükleniyor...</h1>
    </div>
  );
}
