import Loading from "components/Loading";
import React, { useEffect, useState } from "react"; 
import {  useSelector } from "react-redux";
import { AppUtils } from "utils/utils"; 
import CustomPieGraph from "components/Nivo/pie_graph";

export default function PanelDetailPieGraph() {
  const selectedWorks = useSelector((state) => state.workSlice.selectedWorks);
  const [data, setData] = useState(null);

  const getCompletedWorks = () =>
    selectedWorks.map((v) => {
      const startedDateOnlyDate = v.startedDate.slice(0, 10);
      const endedDateOnlyDate = v.endedDate.slice(0, 10);
      return { ...v, startedDateOnlyDate, endedDateOnlyDate };
    });
  useEffect(() => {
    if (selectedWorks) {
      var _data = AppUtils.groupBy(selectedWorks, "divisionName");
      _data = _data.map((v) => {
        var _totalUsedTime = v.value
          .reduce((partialSum, a) => partialSum + a.usedTime, 0)
          .toFixed(0);
        return {
          id: v.key,
          label: v.key,
          value: _totalUsedTime,
        };
      });

      setData(_data);
    }
  }, [selectedWorks]);
  return <>{data == null ? <Loading /> : buildTable()}</>;

  function buildTable() {
    return (
      <div className="flex   h-[400px] w-[800px] justify-center     ">
        <CustomPieGraph data={data}></CustomPieGraph>
      </div>
    );
  }
}
