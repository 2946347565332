import React from "react";
import { Toaster } from "react-hot-toast";
import WorkingHoursWorklyTable from "./components/table";
export default function WorkingHours() {
  return (
    <div className="flex   px-4    w-full  relative">
      <WorkingHoursWorklyTable />
      <Toaster position="top-right" />
    </div>
  );
}
