import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PrivateRoute({ children }) {
  const user = useSelector((state) => state.authSlice.user); 
  if (!user) {
    return <Navigate to="/admin/login" replace={true} />;
  } 
  return children;
}
