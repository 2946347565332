import React, { useState } from "react";
import { Form, Formik } from "formik";
import { classNames } from "primereact/utils";
import Input from "components/Form/Input";
import { useDispatch, useSelector } from "react-redux";
import { setCompany } from "store/company";
import CompanyService from "services/company_service";
import { showError, showSucces } from "utils/error";
import CustomButton from "components/Form/Button";
import CustomDialog from "components/dialog";
import { IoIosWarning } from "react-icons/io";
import { mutate } from "swr";
import ApiConstants from "services/api";

export default function EditCompany() {
  const company = useSelector((state) => state.companySlice.company);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState(false);
  return (
    <>
      <CustomDialog
        isOpen={dialog}
        setIsOpen={setDialog}
        title={"Uyarı"}
        body={
          <div className="flex  flex-col  items-end">
            <div>
              <div className="flex justify-center items-center gap-x-2">
                <IoIosWarning className="h-14 w-14 text-red-500"></IoIosWarning>
                Müşteriyi silmek üzeresiniz. Bu işlem geri alınamaz. Devam etmek
                istediğinize emin misiniz?
              </div>
            </div>
            <div className="flex gap-x-4">
              <CustomButton
                className="text-slate-400 font-thin"
                onClick={() => setDialog(false)}
              >
                Vazgeç
              </CustomButton>
              <CustomButton
                className="bg-red-100 text-red-800 font-bold py-2"
                onClick={async () => {
                  try {
                    setDialog(false);
                    await CompanyService.delete(company._id);
                    dispatch(setCompany(null));
                    mutate(ApiConstants.COMPANIES);
                    showSucces("Müşteri silindi");
                  } catch (error) {
                    setLoading(false);
                    showError(error);
                  }
                }}
              >
                Devam et
              </CustomButton>
            </div>
          </div>
        }
      ></CustomDialog>
      <div
        className={classNames(
          "shadow rounded-lg flex  flex-col items-center divide-y gap-4 transition-all bg-white p-4 "
        )}
      >
        <div className="w-full">
          <span className="text-red-400 font-bold text-lg self-start ">
            {company == null ? "Müşteri Ekle" : "Müşteri Güncelle"}
          </span>
          <Formik
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              companyName: company?.companyName || "",
            }}
            onSubmit={async (values, actions) => {
              setLoading(true);
              try {
                if (company) {
                  await CompanyService.update(company._id, values);
                  showSucces("Müşteri güncellendi");
                } else {
                  await CompanyService.add(values);
                  actions.resetForm();
                  showSucces("Müşteri kaydedildi");
                }

                mutate(ApiConstants.COMPANIES);

              } catch (error) {
                setLoading(false);
                showError(error);
              }
              setLoading(false);
            }}
          >
            {({ values }) => (
              <Form className="gap-y-4 flex flex-col  w-full   pt-8 ">
                <Input
                  type="text"
                  label="Müşteri"
                  name="companyName"
                  required
                />
                <div className="flex w-full justify-evenly pt-3 self-end">
                  {company && (
                    <CustomButton
                      onClick={() => setDialog(true)}
                      type="reset"
                      className=" hover:bg-red-100 text-red-500 py-2 "
                    >
                      Sil
                    </CustomButton>
                  )}
                  <CustomButton
                    onClick={() => dispatch(setCompany(null))}
                    type="reset"
                    className=" hover:bg-gray-100 text-gray-400 py-2"
                  >
                    Temizle
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    className=" bg-red-50 text-red-600 hover:bg-red-100  py-2"
                    loading={loading ? true : false}
                  >
                    {company ? "Güncelle" : "Ekle"}
                  </CustomButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
