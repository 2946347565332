import ApiConstants from "./api";
import { deleteData, fetchData, postData, putData } from "./axios_helper";
class CompanyService {
  static async getAll() {
    const res = await fetchData(ApiConstants.COMPANIES);
    return res;
  }
  static async add(data) {
    const res = await postData(ApiConstants.COMPANIES, data);
    return res;
  }

  static async update(id, data) {
    const res = await putData(ApiConstants.COMPANIES + "/" + id, data);
    return res;
  }
  static async delete(id) {
    const res = await deleteData(ApiConstants.COMPANIES + "/" + id);
    return res;
  }
}

export default CompanyService;
