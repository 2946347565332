import React, { Fragment, useState } from "react"; 
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import classNames from "classnames";
export const CustomListBoxWithoutFormik = ({ label,values, selected,setSelected, ...probs }) => {  

  const [query, setQuery] = useState("");
  const filteredValues =
    query === ""
      ? values
      : values.filter((value) =>
          value
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <div className=" rounded-lg  ">
      <Combobox 
        value={selected ?? ""}
        onChange={setSelected}
        
      >
        <div className="relative rounded-lg ">
          <div className="relative ring-transparent	 text-inputText ">
            <Combobox.Input 
          
              className={classNames(
                "border-none  text-sm flex w-full  shrink-0  h-10 border-red-500 focus:shadow-[0_0_0_0.2rem_rgba(255,113,113,0.5)]  focus:ring-inherit bg-inputBackground rounded-lg",
                {
                  "pt-[1.32rem]": selected || query, 
                }
              )}
              displayValue={(value) => value}
              onChange={(event) => setQuery(event.target.value)}
            />
            <small
              className={classNames({
                "absolute  left-[13px] cursor-text pointer-events-none text-gray-400 -translate-y-1/2 transition-all ": true,
                "text-sm top-1/2": !selected || query,
                "text-xs top-2.5": selected || query, 
              })}
            >
              {label}
            </small>
            <Combobox.Button className=" absolute  inset-y-0 right-2 flex items-center ">
              <SelectorIcon
                className="h-5 w-5 text-gray-400 "
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className=" absolute z-50 rounded-lg mt-1 max-h-60 w-full overflow-auto  bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredValues.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-red-800">
                  Bulunamadı.
                </div>
              ) : (
                filteredValues.map((value) => (
                  <Combobox.Option
                    key={value}
                    className={({ active }) =>
                      `relative cursor-default   select-none py-2 pl-10 pr-4 ${
                        active
                          ? "bg-red-100    font-bold text-red-800"
                          : "text-red-800 "
                      }`
                    }
                    value={value}
                  >
                    {({ active }) => {
                      return (
                        <>
                          <span
                            className={`block truncate ${
                              selected == value ? "font-medium" : "font-normal"
                            }`}
                          >
                            {value}
                          </span>
                          {selected == value ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? " " : "text-red-800 "
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};
