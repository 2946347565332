import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: JSON.parse(localStorage.getItem("tdk_user")),
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("tdk_user",JSON.stringify( action.payload));
      localStorage.setItem("tdk_acces_token", action.payload.token);
      console.log(action.payload.token);
    },
    logout: (state) => {
      localStorage.removeItem("tdk_user" );
      localStorage.removeItem("tdk_acces_token" );
      state.user = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { login, logout, setLoading } = authSlice.actions;
export default authSlice.reducer;
