import Lottie from "react-lottie";

 
export default function CustomLottie({animationData,size=400}) {
    const defaultOptions = {
        loop: true, 
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return (
      <div>
        <Lottie 
        isClickToPauseDisabled
          options={defaultOptions}
          height={size}
          
          width={size}
        />
      </div>
    );
  }