import classNames from "classnames";
import Loading from "components/Loading";
import Table from "components/Table";
import { OverflowCell } from "components/Table/components/overflow";
import { useMemo } from "react";
import { FiEdit } from "react-icons/fi";
import { MdOutlineContentCopy } from "react-icons/md";
import { useDispatch } from "react-redux";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import { setPanel } from "store/panel";

export default function PanelTable() {
  const dispatch = useDispatch();

  const { data: panels, isLoading, error } = useFetchData(ApiConstants.PANELS+"?withDetails=true");

  const columns = useMemo(
    () => [
      {
        Header: "Pano Adı",
        accessor: "panelName",
        Cell: OverflowCell,
        canGroupBy: false,
      },
      {
        Header: "Pano Kodu",
        accessor: "panelCode",
        canGroupBy: false,
      },
      {
        Header: "Proje Kodu",
        accessor: "projectName",
        canGroupBy: false,
      },
      {
        Header: "Müşteri",
        accessor: "companyName",
        canGroupBy: false,
        Cell: OverflowCell,
      },
      {
        Header: "Son Müşteri",
        accessor: "lastCompanyName",
        canGroupBy: false,
        Cell: OverflowCell,
      },
      {
        Header: "Durum",
        accessor: "state",
        Cell: PanelState,
        canGroupBy: false,
      },
      {
        Header: " ",
        accessor: null,
        Cell: EditBtn,
        canGroupBy: false,
      },
    ],
    [panels]
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  return panels == null ? (
    <Loading />
  ) : (
    <Table className="h-full" columns={columns} data={panels} pageSize={13} />
  );
  function EditBtn({ value, column, row }) {
    return (
      <div className="flex gap-x-2 justify-end">
        <div className="  flex justify-end">
          <button
            onClick={() => {
              // row.original._id = null; 
              dispatch(setPanel({ ...row.original, _id: null }));
            }}
            className="bg-violet-100 text-violet-500 p-1.5 rounded-md  justify-end flex "
          >
            <MdOutlineContentCopy />
          </button>
        </div>
        <div className="  flex justify-end">
          <button
            onClick={() => {
              dispatch(setPanel(row.original));
            }}
            className="bg-red-100 text-red-500 p-1.5 rounded-md  justify-end flex "
          >
            <FiEdit />
          </button>
        </div>{" "}
      </div>
    );
  }
  function PanelState({ value, column, row }) {
    return (
      <div className="  flex  ">
        <div
          className={classNames(
            " px-4 py-1 text-sm font-semibold rounded-md ",
            {
              "bg-green-50 text-green-500 ":
                row.original.state === "Tamamlandı",
              "bg-red-50 text-red-500 ": row.original.state === "Askıda",
              "bg-orange-50 text-orange-500 ":
                row.original.state === "Devam ediyor",
            }
          )}
        >
          {row.original.state}
        </div>
      </div>
    );
  }
}
