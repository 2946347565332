import React from "react";
import { Field, useField } from "formik";
import { AppUtils } from "utils/utils";
import PreviewImage from "components/PreviewImage";

export default function FileInput({ ...probs }) {
  const [field, meta, helpers] = useField(probs); 

  return (
    <div className="flex items-center space-x-6">
      <div className="shrink-0">
       {  <PreviewImage
          className="h-16 w-16 object-cover rounded-full"
          file={typeof field.value === 'object' ? field.value : null}
          src={typeof field.value === 'string' ? field.value : null}
        />}
      </div>
      <label className="block">
        <span className="sr-only">Choose profile photo</span>
        <input
          type="file"
          className="block w-full text-sm text-slate-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-full file:border-none
        file:text-sm file:font-semibold
        file:bg-red-50 file:text-red-700
        hover:file:bg-red-100"
        onChange={(event) => {  
          helpers.setValue( event.currentTarget.files[0] );
        }}
          // {...field}
          {...probs}
        />
      </label>
    </div>
  );
}
