import toast from 'react-hot-toast';
const showError = error => {  
  if (typeof error  === "string") { 
    toast.error(error);
  }
  else if (error.response) {  
    toast.error(error.response.data.message);
  } else if (error.request) { 
    toast.error(error.request.response.message);
  } else {
    toast.error("Error", error.message);
  } 
};
const showSucces = msg => {
  toast.success(msg);
};
export   {showError,showSucces};
