import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  panels: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setPanels: (state, action) => {
      state.panels = action.payload;
    },
  },
});

export const { setPanels } = dashboardSlice.actions;
export default dashboardSlice.reducer;
